import React from 'react';
import defaultClasses from './image.css';
import { arrayOf, bool, oneOf, shape, string } from 'prop-types';
import resolveLinkProps from '../../resolveLinkProps';
import { mergeClasses } from '../../classify';
import { Link } from 'react-router-dom';
import { makeOptimizedUrl as resourceUrl } from '../../makeUrl';
import { FadeIn } from 'react-lazyload-fadein';
import {useWindowSize} from "@magento/peregrine";
import {useGlobalOptions} from '@corratech/context-provider';

/**
 * Page Builder Image component.
 *
 * This component is part of the Page Builder / PWA integration. It can be consumed without Page Builder.
 *
 * @typedef Image
 * @kind functional component
 *
 * @param {props} props React component props
 *
 * @returns {React.Element} A React component that displays an Image.
 */
const Image = props => {
    const classes = mergeClasses(defaultClasses, props.classes);
    const globalOptions = useGlobalOptions();

    const {
        imgWidth,
        imgHeight,
        imgHeightMob,
        imgWidthMob,
        desktopImage,
        mobileImage,
        altText,
        title,
        link,
        linkType,
        openInNewTab,
        caption,
        textAlign,
        border,
        borderColor,
        borderWidth,
        borderRadius,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft,
        cssClasses = []
    } = props;

    const figureStyles = {
        textAlign,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft
    };
    const imageStyles = {
        border,
        borderColor,
        borderWidth,
        borderRadius
    };

    // Don't render anything if there is no image to be rendered
    if (!desktopImage && !mobileImage) {
        return null;
    }

    const windowSize = useWindowSize();
    const isMobile =
        windowSize.innerWidth <=
        (globalOptions.viewport ? globalOptions.viewport.mobile || 767 : '');

    const SourceFragment = mobileImage ? (
        <source
            media="(max-width: 767px)"
            srcSet={resourceUrl(mobileImage, {
                type: 'image-wysiwyg',
                quality: 85
            })}
        />
    ) : (
        ''
    );
    const PictureFragment = (
        <>
            {
                (imgWidth && imgHeight) ? (
                    <>
                        <picture>
                            {SourceFragment}

                            <img
                                className={classes.img}
                                src={resourceUrl(desktopImage, {
                                    type: 'image-wysiwyg',
                                    quality: 85
                                })}
                                alt={altText}
                                style={imageStyles}
                                width={isMobile ? imgWidthMob : imgWidth}
                                height={isMobile ? imgHeightMob : imgHeight}
                            />
                        </picture>
                        {caption ? <figcaption>{caption}</figcaption> : ''}
                    </>
                ) : (
                    <FadeIn height={100} duration={50}>
                        {onload => (
                            <>
                                <picture>
                                    {SourceFragment}

                                    <img
                                        className={classes.img}
                                        src={resourceUrl(desktopImage, {
                                            type: 'image-wysiwyg',
                                            quality: 85
                                        })}
                                        onLoad={onload}
                                        title={title}
                                        alt={altText}
                                        style={imageStyles}
                                        width={isMobile ? imgWidthMob : imgWidth}
                                        height={isMobile ? imgHeightMob : imgHeight}
                                    />
                                </picture>
                                {caption ? <figcaption>{caption}</figcaption> : ''}
                            </>
                        )}
                    </FadeIn>
                )
            }
        </>
    );

    if (typeof link === 'string') {
        const linkProps = resolveLinkProps(link, linkType);
        const LinkComponent = linkProps.to ? Link : 'a';

        return (
            <figure style={figureStyles} className={cssClasses.join(' ')}>
                <LinkComponent
                    {...linkProps}
                    {...(openInNewTab ? { target: '_blank' } : '')}
                    aria-label={altText}
                >
                    {PictureFragment}
                </LinkComponent>
            </figure>
        );
    } else {
        return (
            <figure style={figureStyles} className={cssClasses.join(' ')}>
                {PictureFragment}
            </figure>
        );
    }
};

/**
 * Props for {@link Image}
 *
 * @typedef props
 *
 * @property {Object} classes An object containing the class names for the Image
 * @property {String} classes.img CSS classes for the img element
 * @property {String} desktopImage URL src of the desktop image
 * @property {String} mobileImage URL src of the mobile image
 * @property {String} altText Alternate text
 * @property {String} title Title of the image
 * @property {String} link URL to redirect to
 * @property {String} linkType Type of link
 * @property {bool} openInNewTab Flag to indicate if link should be opened in a new tab
 * @property {String} caption Caption for the image
 * @property {String} textAlign Alignment of the divider within the parent container
 * @property {String} border CSS border property
 * @property {String} borderColor CSS border color property
 * @property {String} borderWidth CSS border width property
 * @property {String} borderRadius CSS border radius property
 * @property {String} marginTop CSS margin top property
 * @property {String} marginRight CSS margin right property
 * @property {String} marginBottom CSS margin bottom property
 * @property {String} marginLeft CSS margin left property
 * @property {String} paddingTop CSS padding top property
 * @property {String} paddingRight CSS padding right property
 * @property {String} paddingBottom CSS padding bottom property
 * @property {String} paddingLeft CSS padding left property
 * @property {Array} cssClasses List of CSS classes to be applied to the component
 */
Image.propTypes = {
    classes: shape({
        img: string
    }),
    desktopImage: string,
    mobileImage: string,
    altText: string,
    title: string,
    link: string,
    linkType: oneOf(['default', 'category', 'product', 'page']),
    openInNewTab: bool,
    caption: string,
    textAlign: string,
    border: string,
    borderColor: string,
    borderWidth: string,
    borderRadius: string,
    marginTop: string,
    marginRight: string,
    marginBottom: string,
    marginLeft: string,
    paddingTop: string,
    paddingRight: string,
    paddingBottom: string,
    cssClasses: arrayOf(string)
};

export default Image;
