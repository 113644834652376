import React, { Fragment, useEffect, useState } from 'react';
import { func, shape, string, number, object, bool } from 'prop-types';
import { useQuery, useLazyQuery } from 'react-apollo';
import GET_CMS_PAGE from './getCmsPage.graphql';
import { RichContent } from '@corratech/rich-content';
import { SEO } from '@corratech/seo';
import ScanYourFace from 'ModulesPath/PerfectCorp/ScanYourFace';
import { getSEODataString } from 'ModulesPath/Seo/Data';
import { Helmet } from 'react-helmet';

export const CmsPageComponent = props => {
    const { id, extend, config } = props;
    const [faqSEOSchema, setFaqSEOSchema] = useState(false);

    const { loading, error, data } = useQuery(GET_CMS_PAGE, {
        variables: { id, onServer: props.onServer },
        fetchPolicy: props.fetchPolicy,
        context: {
            fetchOptions: {
                method: props.requestMethod || 'GET'
            }
        }
    });

    const [getSEOSchema] = useLazyQuery(getSEODataString, {
        fetchPolicy: 'cache-and-network',
        variables: {
            blockId: 'faq-seo-schema'
        },
        onCompleted: res => {
            const seo_data_string = res?.seo_data?.seo_data_string;
            const { block } =
                (!!seo_data_string && JSON.parse(seo_data_string)) || {};
            if (block) {
                const faqSeoContent = {
                    '@context': 'https://schema.org/',
                    '@type': 'FAQPage',
                    mainEntity: JSON.parse(block)
                };
                setFaqSEOSchema(faqSeoContent);
            }
        }
    });

    useEffect(() => {
        if (data?.cmsPage?.url_key === 'faqs') {
            getSEOSchema();
        }
    }, [data?.cmsPage?.content]);

    if (!data && loading) {
        return props.loadingIndicator || null;
    }

    if (!data && error) {
        return <div>Data Fetch Error</div>;
    }

    if (data) {
        if (
            data.cmsPage.content &&
            data.cmsPage.content.length > 0 &&
            !data.cmsPage.content.includes('CMS homepage content goes here.')
        ) {
            let baseUrl = process.env.URL_BASE || location.origin;
            baseUrl =
                baseUrl[baseUrl.length - 1] === '/' ? baseUrl : baseUrl + '/';

            let breadcrumbs = [];
            if (window.location.pathname != '/') {
                breadcrumbs = [
                    {
                        '@type': 'ListItem',
                        position: 2,
                        item: {
                            '@id': baseUrl + data?.cmsPage?.url_key || '',
                            name: data?.cmsPage?.title
                        }
                    }
                ];
            }

            return (
                <Fragment>
                    <Helmet>
                        {faqSEOSchema && (
                            <script type={'application/ld+json'}>
                                {JSON.stringify(faqSEOSchema, null, 2)}
                            </script>
                        )}
                    </Helmet>
                    <SEO cmsPage={data.cmsPage} pageBreadcrumbs={breadcrumbs} />
                    <ScanYourFace cmsPage={data?.cmsPage} />
                    <div id="cms-page-content">
                        <RichContent
                            html={data.cmsPage.content}
                            extend={extend}
                            config={config}
                        />
                    </div>
                </Fragment>
            );
        }
    }
    return null;
};

CmsPageComponent.propTypes = {
    children: func,
    classes: shape({
        block: string,
        content: string,
        root: string
    }),
    id: number.isRequired,
    loadingIndicator: object,
    onServer: bool,
    fetchPolicy: string
};

CmsPageComponent.defaultProps = {
    onServer: false,
    fetchPolicy: 'cache-and-network'
};
