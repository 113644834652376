/** @jsx jsx */
import React, {useState, useEffect, useRef} from 'react';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { SubMenuCMS } from '../SubMenuCMS';
import { forceCheck } from 'react-lazyload';
let examineClasses = classNames.bind();

export const TopCategory = props => {
    const {
        item,
        menuLevel,
        setMenuLevel,
        isTab,
        Link,
        showSubmenu,
        showCMSBlock,
        showOnyCMSBlock,
        activeTopmenu,
        setActiveTopmenu,
        activeSubmenu,
        setActiveSubmenu,
        isShownSubMenu,
        setIsShownSubMenu,
        setTopToggle,
        setActiveSubMenuItem,
        categoryUrlSuffix
    } = props;
    const [toggle, setToggle] = useState(false);
    const locationPath = window.location.pathname;
    const categoryURL = `/${item.url_key}${categoryUrlSuffix}`;
    const currentItemRef = useRef(null);
    const [shownSubMenuId, setShownSubMenuId] = useState(null);

    const handleOnMouseLeave = () => {
        setShownSubMenuId(null);
    };

    useEffect(() => {
        const currentItem = currentItemRef.current;
        const handleBlur = (event) => {
            if (currentItem && !currentItem.contains(event.relatedTarget)) {
                setShownSubMenuId(null);
            }
        };

        currentItem.addEventListener('blur', handleBlur, true);

        return () => {
            currentItem.removeEventListener('blur', handleBlur, true);
        };
    }, []);

    useEffect(() => {
        if (locationPath == categoryURL) {
            setActiveTopmenu(item.id);
        }
    }, [locationPath, categoryURL]);

    const lazyForceImage = () => {
        if (forceCheck) forceCheck();
    };

    const handleSubMenuOpen = () => {
        const id = !isShownSubMenu ? item.id : (shownSubMenuId === item.id) ? null : item.id;

        setShownSubMenuId(id);
        setIsShownSubMenu(id);
    }

    const handleOnMouseEnter = () => {
        lazyForceImage();
        handleSubMenuOpen();
    };

    return (
        <li
            className={`
              menu-item
              level-${menuLevel}
              ${toggle ? 'open' : activeTopmenu === item.id ? 'active' : ''}
              ${
                shownSubMenuId && isShownSubMenu === item.id ? 'submenu-open' : ''}
              `}
            ref={currentItemRef}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            css={props.css}
        >
            {!isTab && (
                <Link
                    className={examineClasses('levelTop')}
                    to={`/${item.url_key}${categoryUrlSuffix}`}
                    onClick={() => {
                        setActiveTopmenu(item.id);
                        setTopToggle(false);
                    }}
                >
                    <span className={'name'}>{item.name}</span>
                </Link>
            )}

            {isTab ? (
                <button
                    className={examineClasses('levelTop')}
                    onClick={() => {
                        // setToggle(!toggle);
                        setMenuLevel(2);
                        setActiveSubMenuItem(item);
                        lazyForceImage();
                    }}
                >
                    <span className={'name'}>{item.name}</span>
                </button>
            ) : (
                <button
                    className={'levelTop-subMenuOpenBtn'}
                    title={`Open subcategories for ${item.name}`}
                    onClick={handleSubMenuOpen}
                >
                </button>
            )}

            {!isTab ? (
                <div className={'subCat'}>
                    <div className={'subCatParent'}>
                        <div>
                            {showSubmenu ? (
                                <div
                                    className={examineClasses('subCatWrapper')}
                                ></div>
                            ) : (
                                ''
                            )}

                            <div
                                className={examineClasses(
                                    `${'imageWrapper'} ${'imageWrapper-'}${menuLevel}`
                                )}
                            >
                                <SubMenuCMS item={item} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ''
            )}
        </li>
    );
};
