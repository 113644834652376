import React, { useRef, useContext } from 'react';
import { Checkbox } from '@corratech/form-components';
import {useGlobalOptions} from '@corratech/context-provider';

const SMSMarketingCheckbox = props => {
    const refCheckbox = useRef();
    const {
        smsMarkettingSubscribeChecked,
        setSMSMarkettingSubscribeChecked
    } = props;
    const projectConfig = useGlobalOptions();
    const code = projectConfig.storeConfig.code;
    const personalizedText = code === 'us'
        ? (
            <>
                By checking this box, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. cart reminders) from Elemis at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg & data rates may apply. View <a target="_blank" href="http://attn.tv/elemis/terms.html" className="privacy-link">Terms</a> & <a target="_blank" href="https://attnl.tv/legal/p/A2s" className="privacy-link">Privacy</a>.
            </>
        )
        : (
            <>
                I agree to receive promotional and personalised marketing text messages from ELEMIS.
            </>
        );

    const handleChange = () => {
        if (refCheckbox.current.checked) {
            setSMSMarkettingSubscribeChecked(true);
        } else {
            setSMSMarkettingSubscribeChecked(false);
        }
    };

    return (
        <span className={'newsletter-checkbox'}>
            <Checkbox
                ref={refCheckbox}
                field="sms_marketing"
                onChange={() => handleChange()}
                id={'sms_marketing'}
                label={
                    <span>
                        {personalizedText}
                    </span>
                }
                fieldState={{ value: smsMarkettingSubscribeChecked }}
            />
        </span>
    );
};

export default SMSMarketingCheckbox;
