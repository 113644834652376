import React, { Component, Fragment } from 'react';
import { bool, node, shape, string, func, object, oneOfType } from 'prop-types';
import { BasicCheckbox, asField } from 'informed';
import { Message } from '../Message';
import Icon from '../Icon';
//import { FiCheck as CheckIcon } from 'react-icons/fi';
import { Check as CheckIcon } from 'react-feather';
import './Checkbox.less';

export const Checkbox = props => {
    const { fieldState, id, label, message, icon, ...rest } = props;
    const { value: checked } = fieldState;

    return (
        <Fragment>
            <label className={'root-checkbox'} htmlFor={id}>
                <span className={'icon-checkbox'}>
                    {checked && <Icon src={icon} size={18} />}
                </span>
                <BasicCheckbox
                    {...rest}
                    className={'input-checkbox'}
                    fieldState={fieldState}
                    id={id}
                />
                <span className={'label-checkbox'}>{label}</span>
            </label>
            <Message fieldState={fieldState}>{message}</Message>
        </Fragment>
    );
};

Checkbox.propTypes = {
    classes: shape({
        icon: string,
        input: string,
        label: string,
        message: string,
        root: string
    }),
    field: string.isRequired,
    fieldState: shape({
        value: bool
    }).isRequired,
    id: string,
    label: node.isRequired,
    message: node,
    icon: oneOfType([func, object])
};

Checkbox.defaultProps = {
    icon: CheckIcon
};

export default asField(Checkbox);
