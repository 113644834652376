import React from 'react';
import { PlaceOrder } from './PlaceOrder';
export const KlarnaPayLater = props => {
    const {
        buttonState,
        setKlarnaPayment,
        handlePlaceOrder,
        klarnaType,
        getBillingAddress,
        getShippingAddress,
        setPaymentProcessing,
        paymentProcessing,
        getDiscountAmount,
        getShippingOption,
        getDiscountOrderLine,
        getCartItems,
        PrivacyPolicy,
        privacyPolicyLink,
        NewsletterCheckbox
    } = props;

    return (
        <div className={`klarna-content ${klarnaType == 'pay_later' ? 'active' : ''}`}>
            <div id="klarna-payments-container-pay_later"></div>

            {klarnaType == 'pay_later' &&
                (<PlaceOrder
                    buttonState={buttonState}
                    setKlarnaPayment={setKlarnaPayment}
                    handlePlaceOrder={handlePlaceOrder}
                    klarnaType={klarnaType}
                    getBillingAddress={getBillingAddress}
                    getDiscountAmount={getDiscountAmount}
                    getShippingOption={getShippingOption}
                    getDiscountOrderLine={getDiscountOrderLine}
                    getCartItems={getCartItems}
                    getShippingAddress={getShippingAddress}
                    setPaymentProcessing={setPaymentProcessing}
                    PrivacyPolicy={PrivacyPolicy}
                    privacyPolicyLink={privacyPolicyLink}
                    paymentProcessing={paymentProcessing}
                    NewsletterCheckbox={NewsletterCheckbox}
                />)}
        </div>
    );
}
export default KlarnaPayLater;