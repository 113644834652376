import React, { useEffect, useState } from 'react';
import Mobile from './Mobile';

const ScanYourFace = props => {
    const [showPCWidget, setShowPCWidget] = useState(false);

    const { cmsPage } = props;

    useEffect(() => {
        if (!cmsPage?.content || cmsPage?.url_key != 'skin-analysis') {
            return;
        }

        const handleClick = () => {
            document
                .querySelectorAll('#cms-page-content')
                .forEach(function(el) {
                    el.style.display = 'none';
                });

            setShowPCWidget(true);
        };

        document.addEventListener('click', function(e) {
            const target = e.target.closest('.open-pc-widget');

            if (target) {
                handleClick();
            }
        });
    }, [cmsPage]);

    if (!showPCWidget) {
        return null;
    }

    return <Mobile />;
};

export default ScanYourFace;
