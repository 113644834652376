import React, {
    Fragment,
    useEffect,
    useState,
    useCallback,
    useContext
} from 'react';
import { VisualSwatch } from './VisualSwatch';
import { Link } from 'react-router-dom';
import { PLPManagerStore } from 'ModulesPath/Catalog/PLP/PLPManager';

export const Options = props => {
    const {
        parent,
        isSelected,
        isAvailable,
        optionSelect,
        allowSelection,
        optionLimit,
        productUrl,
        moreIcon,
        viewMorePDP,
        variantRef,
        product,
        setSelectedDefaultOption,
        setIsClick
    } = props;
    const seeMoreTxt = 'View More';
    const seeLessTxt = 'View Less';
    const [expandedClass, setExpandedClass] = useState('');
    const configVal = parent.values;
    const [moreText, setMoreText] = useState(seeMoreTxt);
    const initialConfigOptions =
        optionLimit === false ? configVal : configVal.slice(0, optionLimit);
    const [options, optionsList] = useState(initialConfigOptions);
    const scrollElement =
        variantRef && variantRef.current
            ? variantRef.current.offsetTop - 15
            : 0;

    const viewAll = useCallback(() => {
        if (options.length < configVal.length) {
            optionsList(configVal);
            setMoreText(seeLessTxt);
            setExpandedClass('expanded');
        } else {
            optionsList(initialConfigOptions);
            setMoreText(seeMoreTxt);
            setExpandedClass('');
            window.scrollTo({ behavior: 'smooth', top: scrollElement });
        }
    }, [options]);

    const { plpState } = useContext(PLPManagerStore);

    let priceMin = 0;
    let priceMax = 0;

    if (plpState) {
        const { filters, availableFilters } = plpState;

        const priceFilterAttr = 'price_filter';

        const val = filters?.[priceFilterAttr]?.['in']?.[0];

        if (availableFilters && availableFilters.length) {
            const priceFilter = availableFilters.find(
                filter => filter.attribute_code === priceFilterAttr
            );
            const priceLabel = priceFilter?.options.find(
                option => option.value == val
            );

            const value_string = priceLabel?.label;
            if (value_string) {
                const priceRange = value_string
                    .replace(/[^0-9\-]/g, '')
                    .split('-');
                priceMin = priceRange[0];
                priceMax = priceRange[1];
            }
        }
    }

    useEffect(() => {
        options.map(child => {
            const isThisAvailable = isAvailable(parent.attribute_code, child);
            if (isThisAvailable.product !== false) {
                const finalPrice =
                    isThisAvailable?.product?.price_range?.minimum_price
                        ?.final_price?.value;
                if (finalPrice >= priceMin && priceMax >= finalPrice) {
                    const selectedOptions = {
                        attribute_code: parent.attribute_code,
                        value: child.value_index,
                        label: child.label,
                        product_id: product.id
                    };
                    setSelectedDefaultOption(selectedOptions);
                }
            }
        });
    }, [product]);

    const itemElements = () => {
        return options.map((child, childKey) => {
            const isThisSelected = isSelected(
                parent.attribute_code,
                child.value_index
            );
            const isThisAvailable = isAvailable(parent.attribute_code, child);
            const hasVisual = child.swatch_data != null;
            const isThisOOS =
                isThisAvailable.product !== false &&
                isThisAvailable.allOOS == true
                    ? 'oos'
                    : '';

            return (
                <Fragment key={childKey}>
                    {isThisAvailable.product !== false && (
                        <li className="variant-option">
                            <button
                                role="option"
                                aria-label={child.label}
                                aria-checked={!!isThisSelected}
                                aria-disabled={!!isThisOOS}
                                className={`variant-option-button swatch-data   
                                                ${
                                    parent.attribute_code
                                }-${child.label.toLowerCase()}
                                                ${
                                    hasVisual === true
                                        ? 'visual-option'
                                        : ''
                                }
                                                ${isThisSelected} 
                                                ${isThisOOS}
                                                ${
                                    isThisAvailable.product ===
                                    false
                                        ? 'notavailable'
                                        : ''
                                } `}
                                onClick={() => {
                                    if (
                                        isThisAvailable.product !== false &&
                                        allowSelection(isThisAvailable)
                                    ) {
                                        setIsClick(true);
                                        optionSelect(
                                            parent.attribute_code,
                                            child.value_index,
                                            child.label
                                        );
                                    }
                                }}
                                key={childKey}
                                data-value_index={child.value_index}
                            >
                                {hasVisual &&
                                child.swatch_data.type !== 'TEXT' ? (
                                    <VisualSwatch option={child} />
                                ) : (
                                    child.label
                                )}
                            </button>
                        </li>
                    )}
                </Fragment>
            );
        });
    };

    return (
        <ul className={`variant-options ${expandedClass}`} aria-label="Variant options" role="listbox">
            {itemElements()}
            {optionLimit !== false && configVal.length > optionLimit && (
                <li className="more-options" >
                    {viewMorePDP ? (
                        <a
                            role="option"
                            href={'#'}
                            onClick={e => {
                                e.preventDefault();
                                viewAll();
                            }}
                        >
                            {moreText}
                        </a>
                    ) : (
                        <Link to={productUrl}>
                            {`${moreIcon}${configVal.length - optionLimit}`}
                        </Link>
                    )}
                </li>
            )}
        </ul>
    );
};
