import React, { useRef, useContext, useState, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { mutationCreateNewsSubscriber } from '../graphql/mutationCreateNewsSubscriber';
import { Checkbox } from '@corratech/form-components';
import { useTranslation } from 'react-i18next';
import { CartStore } from '@corratech/context-provider';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const NewsletterCheckbox = props => {
    const [t] = useTranslation();
    const refCheckbox = useRef();
    const { cartState } = useContext(CartStore);
    const [newsletterChecked, setNewsletterChecked] = useState(false);

    useEffect(() => {
        if (cartState.cart.isBillingSameAsShipping) {
            if (
                cartState.cart.shipping_addresses &&
                cartState.cart.shipping_addresses[0] &&
                cartState.cart.shipping_addresses[0].region.label ===
                    'California'
            ) {
                setNewsletterChecked(false);
            } else {
                setNewsletterChecked(true);
            }
        } else {
            if (
                cartState.cart.billing_address &&
                cartState.cart.billing_address.region.label === 'California'
            ) {
                setNewsletterChecked(false);
            } else {
                setNewsletterChecked(true);
            }
        }
    }, [cartState.cart]);

    const [createNewsSubscriber] = useMutation(mutationCreateNewsSubscriber, {
        onError: res => {
            console.warn(res);
        }
    });

    const handleChange = () => {
        if (refCheckbox.current.checked) {
            setNewsletterChecked(true);
            createNewsSubscriber({
                variables: { subscriberEmail: cartState.cart.email }
            });
        } else {
            setNewsletterChecked(false);
        }
    };

    return (
        <>
            <h3 className={'subscription-title'}>
                {t('Sign up for news and exclusive offers from Elemis')}
            </h3>
            <span className={'newsletter-checkbox'}>
                <Checkbox
                    ref={refCheckbox}
                    field="news_letter_subscriber"
                    onChange={() => handleChange()}
                    id={'co-newsletter-subscribe'}
                    label={
                        <span>
                            {t(
                                `I agree to receive email communications from ELEMIS.`
                            )}
                        </span>
                    }
                    fieldState={{ value: newsletterChecked }}
                />
            </span>
        </>
    );
};

export default NewsletterCheckbox;
