import React, { useCallback, useEffect, useState } from 'react';
import { RichContent } from '@corratech/rich-content';
import { Form } from 'informed';
import { useLazyQuery, useMutation } from 'react-apollo';
import {
    Field,
    TextInput,
    Checkbox,
    isRequired,
    validateEmail,
    combine
} from '@corratech/form-components';
import sendPerfectCorpEmail from 'ModulesPath/PerfectCorp/mutations/sendPCEmail.graphql';
import { Link } from 'react-router-dom';
import GET_CMS_BLOCKS from 'ModulesPath/Shell/Footer/getCmsBlocks';
import { useTranslation } from 'react-i18next';
import { SubmitButton } from './SubmitButton';
import AlertMessage from './AlertMessage';
import { getDefaultOptions } from './utils/getDefaultOptions';

const EmailCollectionForm = props => {
    const {
        allProductsData,
        ymkData,
        concerns,
        filter,
        defaultFilter,
        productsData,
        routineData,
        categoryData,
        routineCategoryData,
        allCategoryData
    } = props;
    const [content, setContent] = useState(false);
    const [tnc, setTnc] = useState(true);
    const [message, setMessage] = useState(null);
    const [t] = useTranslation();
    let formData;

    useEffect(() => {
        if (allProductsData?.length) {
            getContentBlock();
        }
    }, [allProductsData?.length]);

    const [getContentBlock] = useLazyQuery(GET_CMS_BLOCKS, {
        variables: { identifiers: 'pc-email-collector', onServer: false },
        fetchPolicy: 'no-cache',
        onCompleted: res => {
            setContent(res?.cmsBlocks?.items?.[0]?.content);
        }
    });

    const [sendEmail, { loading: mutationLoading }] = useMutation(
        sendPerfectCorpEmail,
        {
            onError: error => {
                setMessage({
                    type: 'danger',
                    message: t(`An error occurred while processing your form.`)
                });
            }
        }
    );

    const filterCategory = (sku, data) => {
        return data?.find(category => category?.['SKU Id'] == sku);
    };

    const isConfig = product => {
        return product.__typename == 'ConfigurableProduct';
    };

    const concernScore = () => {
        return [
            {
                title: 'Wrinkles',
                score: ymkData?.wrinkles
            },
            {
                title: 'Firmness',
                score: ymkData?.firmness
            },
            {
                title: 'Redness',
                score: ymkData?.redness
            },
            {
                title: 'Dark Spots',
                score: ymkData?.ageSpots
            },
            {
                title: 'Oil & Pores',
                score: ymkData?.pore
            },
            {
                title: 'Texture',
                score: ymkData?.texture
            }
        ];
    };

    const getConfigurableProductPrice = product => {
        const defaultOption = getDefaultOptions(product);
        const { variants } = product;
        let configProduct = false;

        variants.map(variant => {
            variant.attributes.map(variantAttribute => {
                if (
                    variantAttribute.code == defaultOption?.[0]?.attribute &&
                    variantAttribute.value_index ==
                        defaultOption?.[0].value_index
                ) {
                    configProduct = variant.product;
                }
            });
        });
        if (!configProduct) {
            return product;
        }
        return configProduct;
    };

    const getProductPrice = product => {
        const currency =
            product?.price_range?.minimum_price?.regular_price?.currency;
        let currencySymbol = '$';

        if (currency == 'GBP') {
            currencySymbol = '£';
        } else if (currency == 'EUR') {
            currencySymbol = '€';
        }
        const productPrice = isConfig(product)
            ? getConfigurableProductPrice(product)
            : product;
        const price = parseFloat(
            productPrice?.price_range?.minimum_price?.final_price?.value
        ).toFixed(2);
        return `${currencySymbol}${price}`;
    };

    const getProducts = (product, category) => {
        return product?.map(item => {
            return {
                title: filterCategory(item?.sku, category)?.['Category'],
                subTitle: filterCategory(item?.sku, category)?.[
                    'Category Descriptions'
                ],
                name: item?.name,
                image: item?.small_image?.url,
                sku: item?.sku,
                price: getProductPrice(item),
                url: item?.url_key
            };
        });
    };

    const prepareProducts = type => {
        if (type === 'default' && filter !== defaultFilter) {
            return false;
        }
        if (
            (type === 'concern' || type === 'complete') &&
            filter === defaultFilter
        ) {
            return false;
        }
        if (type === 'complete') {
            return getProducts(routineData, routineCategoryData);
        }
        return getProducts(productsData, categoryData);
    };

    const onSubmitCallback = formApi => {
        formData = formApi;
        formApi.submitForm();
    };

    const handleSubmitForm = async values => {
        try {
            if (!values || typeof formData === 'undefined') {
                return;
            }
            const perfectCorpData = {
                products: ymkData,
                category: allCategoryData
            };
            let data = {
                input: {
                    email: values?.email,
                    total_score: ymkData?.skinHealth,
                    concern_scores: concernScore(),
                    perfect_corp_data: JSON.stringify(perfectCorpData)
                }
            };
            if (
                concerns?.[filter] &&
                typeof concerns?.[filter] !== 'undefined'
            ) {
                data['input']['concern_name'] = concerns?.[filter];
            }
            if (prepareProducts('default')) {
                data['input']['default'] = prepareProducts('default');
            }
            if (prepareProducts('complete')) {
                data['input']['complete'] = prepareProducts('complete');
            }
            if (prepareProducts('concern')) {
                data['input']['concern'] = prepareProducts('concern');
            }

            setMessage(null);

            await sendEmail({
                variables: data
            }).then(response => {
                setTnc(false);
                formData.reset();
                if (response?.data?.sendPerfectCorpEmail?.success) {
                    setMessage({
                        type: 'success',
                        message: t(`Successfully sent email.`)
                    });
                } else {
                    setMessage({
                        type: 'danger',
                        message: t(
                            `An error occurred while processing your form.`
                        )
                    });
                }
                setTimeout(() => {
                    setMessage(null);
                }, 5000);
            });
        } catch (error) {
            console.log('PC Error: ', error);
        }
    };

    const tncLink = (
        <>
            <span>{t(`I accept the `)}</span>
            <Link
                title={t('Terms and Conditions')}
                to={'/terms-and-conditions'}
                target="_blank"
            >
                {t('Terms and Conditions')}
            </Link>
        </>
    );

    const handleClick = useCallback(event => {
        setTnc(event.target.checked);
    }, []);

    return (
        <div className="pc-email-me-wrp">
            <div>
                <RichContent html={content} />
                <Form onSubmit={handleSubmitForm}>
                    <Field
                        label={t('Email Address')}
                        labelText={t('email')}
                        required={true}
                    >
                        <TextInput
                            type={'email'}
                            field="email"
                            id="email"
                            autoComplete="given-email"
                            validate={combine([
                                {
                                    fn: isRequired,
                                    text: t(props.requiredText)
                                },
                                {
                                    fn: validateEmail,
                                    text: t(props.invalidEmailText)
                                }
                            ])}
                            validateOnBlur
                        />
                    </Field>

                    <div className={'field-wrapper'}>
                        <div className="privacy-policy">
                            <div>
                                {t(`I would like to subscribe to Elemis emails to be the first to
                hear about exclusive offers and promotional events and get 15%
                off on my first order.`)}
                            </div>
                            <div>
                                {t(`You can unsubscribe at any time by clicking on the unsubscribe
                link in ELEMIS' emails. For more information on how we use your
                personal data, please see our `)}
                                <Link
                                    title={t('Privacy Policy')}
                                    to={'/privacy-policy'}
                                    target="_blank"
                                >
                                    {t('Privacy Policy')}
                                </Link>
                                .
                            </div>
                        </div>
                        <Checkbox
                            fieldState={{ value: tnc }}
                            label={tncLink}
                            field="tnc"
                            onChange={handleClick}
                        />
                    </div>

                    <SubmitButton
                        onSubmit={onSubmitCallback}
                        loading={!tnc || mutationLoading}
                        btnLabel={'Email me'}
                    />
                </Form>
            </div>

            <div className="alert-message-wrp">
                {message && <AlertMessage message={message} />}
            </div>
        </div>
    );
};

EmailCollectionForm.defaultProps = {
    requiredText: 'This field is required',
    invalidEmailText: 'Please enter a valid email, such as example@example.com'
};

export default EmailCollectionForm;
