/** @jsx jsx */
import React, { Fragment, useState, useEffect } from 'react';
import './MegaMenu.less';
import { jsx } from '@emotion/core';
import { TopCategory } from './TopCategory';
import { useWindowSize } from '@magento/peregrine';
import navigationMenu from '@corratech/megamenu/src/Queries/getNavigationMenu.graphql';
import getNavigationMenuWithCMS from '@corratech/megamenu/src/Queries/getNavigationMenuWithCMS.graphql';
import getNavigationMenuWithOnlyCMS from './getNavigationMenuWithOnlyCMS.graphql';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StoreSwitcherAccordion } from '../StoreSwitcher';
import { SubMenuCMS } from './SubMenuCMS';
import { CmsBlockGroup } from '@corratech/cms-display/src/cmsBlock.js';
import { LeftIcon } from '../../../Icons';
import classNames from 'classnames/bind';
import { AccountForm } from '../AccountForm';
import { isSignedIn } from '@corratech/context-provider';
let examineClasses = classNames.bind();

import PropTypes from 'prop-types';
export const MegaMenu = props => {
    const {
        showSubmenu,
        showCMSBlock,
        projectConfig,
        rootCategoryID,
        categoryUrlSuffix,
        showOnyCMSBlock,
        sampleAccountLinksConfig,
        cmsBlockPushmenu,
        headerClicked,
        toggle,
        setToggle
    } = props;

    const { data: navData, loading: navLoading } = useQuery(
        showOnyCMSBlock
            ? getNavigationMenuWithOnlyCMS
            : showCMSBlock
            ? getNavigationMenuWithCMS
            : navigationMenu,
        {
            variables: {
                id: rootCategoryID
            },
            fetchPolicy: props.fetchPolicy,
            context: {
                fetchOptions: {
                    method: props.requestMethod || 'GET'
                }
            }
        }
    );

    //const [toggle, setToggle] = useState(false);
    const [menuLevel, setMenuLevel] = useState(1);

    const [activeSubMenuItem, setActiveSubMenuItem] = useState(null);
    const [activeSubMenuItemChild, setActiveSubMenuItemChild] = useState(null);
    const windowSize = useWindowSize();
    const isTab = windowSize.innerWidth < projectConfig.viewport.tab;
    const [t] = useTranslation();
    const [activeTopmenu, setActiveTopmenu] = useState('');
    const [activeSubmenu, setActiveSubmenu] = useState('');
    const [isShownSubMenu, setIsShownSubMenu] = useState(null);
    const locationPath = window.location.pathname;

    const isClient = typeof window === 'object';
    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            if (!(window.innerWidth <= projectConfig.viewport.mobile)) {
                setMenuLevel(1);
            }
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        toggle && isTab
            ? document.body.classList.add('pm-open')
            : document.body.classList.remove('pm-open');
    }, [toggle]);

    useEffect(() => {
        let isCategoryUrl = false;
        if (navData && navData.category) {
            navData.category.children.map(item => {
                if (locationPath == `/${item.url_key}${categoryUrlSuffix}`) {
                    isCategoryUrl = true;
                }
            });
        }
        if (!isCategoryUrl) {
            setActiveTopmenu('');
        }
        setToggle(false);
    }, [locationPath]);

    useEffect(() => {
        setToggle(false);
    }, [headerClicked]);

    const handleOnKeyDown = (event) => {
        if (event.key === 'Escape') {
            setIsShownSubMenu(null);
        }
    }

        return (
        <nav
            onKeyDown={handleOnKeyDown}
            className={`${'MegaMenu'} ${toggle ? 'open' : ''}`}
            css={props.css}
        >
            {isTab ? (
                <button
                    aria-label="menu"
                    aria-expanded="false"
                    onClick={() => {
                        setToggle(!toggle);
                        setMenuLevel(1);
                    }}
                    className={'hamburger-icon'}
                >
                    <span>pushmenu</span>
                </button>
            ) : null}
            <div className={'menu-container'}>
                {menuLevel == 1 ? (
                    <Fragment>
                        <ul className={'MenuWrapper'}>
                            {navData
                                ? navData.category.children.map(lvl_1_item => (
                                      <Fragment key={lvl_1_item.id}>
                                          {!!lvl_1_item.include_in_menu && (
                                              <TopCategory
                                                  isTab={isTab}
                                                  item={lvl_1_item}
                                                  menuLevel={menuLevel}
                                                  setMenuLevel={setMenuLevel}
                                                  Link={Link}
                                                  showSubmenu={showSubmenu}
                                                  showCMSBlock={showCMSBlock}
                                                  showOnyCMSBlock={
                                                      showOnyCMSBlock
                                                  }
                                                  activeTopmenu={activeTopmenu}
                                                  isShownSubMenu={isShownSubMenu}
                                                  setIsShownSubMenu={setIsShownSubMenu}
                                                  setActiveTopmenu={
                                                      setActiveTopmenu
                                                  }
                                                  activeSubmenu={activeSubmenu}
                                                  setActiveSubmenu={
                                                      setActiveSubmenu
                                                  }
                                                  setTopToggle={setToggle}
                                                  setActiveSubMenuItem={
                                                      setActiveSubMenuItem
                                                  }
                                                  categoryUrlSuffix={
                                                      categoryUrlSuffix
                                                  }
                                              />
                                          )}
                                      </Fragment>
                                  ))
                                : ''}
                        </ul>
                    </Fragment>
                ) : (
                    ''
                )}
                {isTab && menuLevel == 2 ? (
                    <div
                        className={examineClasses('SubCategoryList', {
                            featured:
                                activeSubMenuItem.isFeatured || props.isFeatured
                        })}
                        css={props.css}
                    >
                        <div
                            className={'subcat-select-top'}
                            onClick={() => {
                                setMenuLevel(1);
                            }}
                        >
                            <div className={'menu-back'}>
                                <button className={'menu-back'}>
                                    <LeftIcon width={5} color={'#002739'} />
                                </button>
                                <h2>{activeSubMenuItem.name}</h2>
                            </div>
                        </div>
                        <SubMenuCMS
                            item={activeSubMenuItem}
                            activeSubmenu={activeSubmenu}
                            isTab={isTab}
                            setActiveSubmenu={setActiveSubmenu}
                            setActiveSubMenuItem={setActiveSubMenuItem}
                            setActiveSubMenuItemChild={
                                setActiveSubMenuItemChild
                            }
                            menuLevel={menuLevel}
                            setMenuLevel={setMenuLevel}
                        />
                    </div>
                ) : (
                    ''
                )}

                {isTab && menuLevel == 3 ? (
                    <div
                        className={examineClasses('SubCategoryList', {
                            featured:
                                activeSubMenuItem.isFeatured || props.isFeatured
                        })}
                        css={props.css}
                    >
                        <SubMenuCMS
                            item={activeSubMenuItemChild}
                            isTab={isTab}
                            activeSubmenu={activeSubmenu}
                            setActiveSubmenu={setActiveSubmenu}
                            setActiveSubMenuItemChild={
                                setActiveSubMenuItemChild
                            }
                            menuLevel={menuLevel}
                            setMenuLevel={setMenuLevel}
                        />
                    </div>
                ) : (
                    ''
                )}
                {toggle && menuLevel == 1 && (
                    <div className="pm-cms-links">
                        {isSignedIn() ? (
                            <AccountForm
                                isTab={true}
                                setPushMenuToggle={setToggle}
                                myAccountLinksConfig={sampleAccountLinksConfig}
                            />
                        ) : (
                            <div className={'account-form'}>
                                <span className={'account-form-link'}>
                                    <div>
                                        <Link
                                            to={'/my-account'}
                                            className={'btn-link'}
                                        >
                                            Account
                                        </Link>
                                    </div>
                                </span>
                            </div>
                        )}
                        {cmsBlockPushmenu != '' && (
                            <CmsBlockGroup identifiers={cmsBlockPushmenu} />
                        )}
                        <div className={'device-sub-menu'}>
                            <StoreSwitcherAccordion />
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
};

MegaMenu.propTypes = {
    showSubmenu: PropTypes.bool,
    showCMSBlock: PropTypes.bool,
    showOnyCMSBlock: PropTypes.bool,
    projectConfig: PropTypes.object,
    rootCategoryID: PropTypes.number,
    categoryUrlSuffix: PropTypes.string,
    fetchPolicy: PropTypes.string
};

MegaMenu.defaultProps = {
    showSubmenu: true,
    showCMSBlock: false,
    showOnyCMSBlock: false,
    projectConfig: { viewport: { mobile: 676, tab: 1024 } },
    rootCategoryID: 2,
    categoryUrlSuffix: '.html',
    fetchPolicy: 'cache-and-network',
    cmsBlockPushmenu: 'cms-pushmenu-secondary-links'
};
