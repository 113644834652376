import React from 'react';
import {VisaIcon} from "@corratech/cart/src/PaymentIcons/VisaIcon";
import {MasterCardIcon} from "@corratech/cart/src/PaymentIcons/MasterCardIcon";
import {JCBIcon} from "@corratech/cart/src/PaymentIcons/JCBIcon";
import {AmericanExpressIcon} from "@corratech/cart/src/PaymentIcons/AmericanExpressIcon";
import {DiscoverIcon} from "@corratech/cart/src/PaymentIcons/DiscoverIcon";
import {PayPalIcon} from "@corratech/cart/src/PaymentIcons/PayPalIcon";
import {KlarnaIcon} from "@corratech/cart/src/PaymentIcons/KlarnaIcon";
import {ApplePayIcon} from "@corratech/cart/src/PaymentIcons/ApplePayIcon";
import '@corratech/cart/src/PaymentIcons/PaymentIcons.less';

export const PaymentIcons = () => {
    return (
        <div className={'minicart-payment-icons'}>
            <span><VisaIcon /></span>
            <span><MasterCardIcon /></span>
            <span><AmericanExpressIcon /></span>
            <span><JCBIcon /></span>
            <span><DiscoverIcon /></span>
            <span><PayPalIcon /></span>
            <span><KlarnaIcon /></span>
            <span><ApplePayIcon /></span>
        </div>
    );
}