import React, {useEffect} from 'react'
import { ReCaptcha } from 'react-recaptcha-v3'
import loadReCaptcha from './loadReCaptcha';
import verifyGoogleRecaptchaV3Token  from './verifyGoogleRecaptchaV3Token.graphql'
import { useMutation } from "react-apollo";
import { useGlobalOptions } from '@corratech/context-provider';

export const ReactGoogleRecaptchaV3 = (props) => {
    const {
        setCaptchaIsValid,
        recaptchaAction,
        setCaptchaInvalidMsg,
        setIsCaptchaEnabled
    } = props;
    const options = useGlobalOptions();
    let isV3ForCaptchaAction = '';
    let recaptchaV3Publickey = '';

    if (options.storeConfig && options.storeConfig["is_v3_"+recaptchaAction]) {
        isV3ForCaptchaAction = options.storeConfig["is_v3_"+recaptchaAction];
    }

    if (options.storeConfig && options.storeConfig.recaptcha_v3_public_key) {
        recaptchaV3Publickey = options.storeConfig.recaptcha_v3_public_key;
    }
    const [verifyGoogleRecaptcha] = useMutation(verifyGoogleRecaptchaV3Token, {
        variables: {
            action: recaptchaAction
        }
    });
    const verifyCallback = token => {
        verifyGoogleRecaptcha({
            variables: {
                token: token
            }
        }).then(res => {
            if(res.data.verifyRecaptchaV3.status === true){
                setCaptchaIsValid(true);
            }else{
                setCaptchaIsValid(false);
                setCaptchaInvalidMsg(res.data.verifyRecaptchaV3.messages);
            }
        }
        ).catch((error) => {
            console.log('GoogleRecaptcha verification error: ' + error);
        });
    }

    useEffect( () => {
        if(isV3ForCaptchaAction === 'recaptcha_v3'){
            setIsCaptchaEnabled(true);
            loadReCaptcha(recaptchaV3Publickey);
        }else {
            setIsCaptchaEnabled(false);
        }
    }, []);

    if(isV3ForCaptchaAction !== 'recaptcha_v3'){
        return null;
    }
        return (
            <div>
                <ReCaptcha
                    action={recaptchaAction}
                    sitekey={recaptchaV3Publickey}
                    verifyCallback={verifyCallback}
                />
            </div>
        )
    }