const getDefaultOptions = product => {
    let firstConfigOption = '';
    const defaultOptionSelection = true;
    let defaultProductOptions = [];

    const instockOptions = getInstockOptions(product);
    if (!defaultOptionSelection || !isConfig(product) || !instockOptions) {
        return defaultProductOptions;
    }

    let defaultOption = null;
    firstConfigOption = product?.configurable_options[0];
    if (!firstConfigOption?.attribute_code) {
        return defaultProductOptions;
    }

    //Default url added from admin
    if (!defaultOption && product[firstConfigOption?.attribute_code]) {
        defaultOption = instockOptions.find(
            ({ value_index }) =>
                value_index === product[firstConfigOption.attribute_code]
        );
    }

    //First availale instock option is set as default
    if (!defaultOption) {
        firstConfigOption.values.map((child, childKey) => {
            if (!defaultOption) {
                defaultOption = instockOptions.find(
                    ({ value_index }) => value_index === child.value_index
                );
            }
        });
    }

    if (defaultOption) {
        defaultProductOptions.push({
            attribute: defaultOption.code,
            value_index: defaultOption.value_index,
            label: defaultOption.label
        });
    }

    return defaultProductOptions;
};

/**
 * create instock options
 */
const getInstockOptions = product => {
    let { variants } = product;
    let availableOptions = [];
    variants?.map(variant => {
        if (variant.product.stock_status == 'IN_STOCK') {
            if (variant.attributes && variant.attributes[0]) {
                availableOptions.push({
                    code: variant.attributes[0].code,
                    value_index: variant.attributes[0].value_index,
                    label: variant.attributes[0].label
                });
            }
        }
    });
    return availableOptions;
};

const isConfig = product => {
    return product.__typename == 'ConfigurableProduct';
};

export { getDefaultOptions };
