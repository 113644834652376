import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { Error as ErrorIcon } from 'ModulesPath/Icons';
import { Check as CheckIcon } from 'react-feather';

const AlertMessage = props => {
    const [t] = useTranslation();
    const { message } = props;
    return (
        <Alert variant={message.type}>
            {message.type == 'success' && (
                <CheckIcon size="14" strokeWidth={'4'} color={'#000'} />
            )}
            {message.type == 'danger' && (
                <ErrorIcon size={9} color={'#B70020'} />
            )}
            {t(message.message)}
        </Alert>
    );
};

export default AlertMessage;
