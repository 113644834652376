import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from 'react-apollo';
import {
    CartStore,
    LoaderStore,
    useGlobalOptions
} from '@corratech/context-provider';
import { deepValue } from '@corratech/tag-manager';
import getPaymentMethods from '@corratech/checkout/PaymentMethods/getPaymentMethods.graphql';
import placeOrderMutation from '@corratech/checkout/graphql/placeOrder.graphql';

import createKlarnaPaymentsSession from './createKlarnaPaymentsSession.graphql';
import { klarnaHelper } from './utils/helper';
import setKlarnaPaymentMethod from './setKlarnaPaymentMethod.graphql';
import setBillingAddressOnCart from './setBillingAddressOnCart.graphql';
import { KlarnaPayLater } from './KlarnaPayLater';
import { KlarnaPayOverTime } from './KlarnaPayOverTime';
import { hasGiftCardProduct } from './utils/hasGiftCardProduct';
import './KlarnaPayment.less';
import { getCurrencySymbol } from './utils/getCurrencySymbol';
import { PaymentFailure } from 'ModulesPath/Checkout';

const KlarnaPayment = props => {
    const {
        dataLayerAction,
        setPaymentFailureError,
        PrivacyPolicy,
        privacyPolicyLink,
        NewsletterCheckbox
    } = props;

    const globalOptions = useGlobalOptions();
    const { storeConfig } = globalOptions;
    const { cartState, dispatch } = useContext(CartStore);
    const [t] = useTranslation();
    const [buttonState, setButtonState] = useState({
        text: t('Continue to Klarna'),
        disabled: false
    });
    const [klarnaType, setKlarnaType] = useState(null);
    const [paymentProcessing, setPaymentProcessing] = useState(false);
    const [klarnaTypeNames, setKlarnaTypeNames] = useState({});
    const LoadingIndicator = useContext(LoaderStore);
    const [klarnaTypeAvailability, setKlarnaTypeAvailability] = useState({
        pay_later: !hasGiftCardProduct(cartState),
        pay_over_time: !hasGiftCardProduct(cartState)
    });
    const [error, setError] = useState(false);

    const klarnaConfig = {
        maximumOrderAmount: storeConfig.klarna_message_maximum_order_total,
        currencySymbol: getCurrencySymbol(storeConfig.base_currency_code)
    };

    const isKlarnaTypeDisabled = () => {
        const hasGiftCard = hasGiftCardProduct(cartState);
        const grandTotal = deepValue(
            cartState,
            ['cart', 'prices', 'grand_total', 'value'],
            0
        );
        const klarnaOrderMax = storeConfig.klarna_message_maximum_order_total;

        setKlarnaTypeAvailability({
            pay_later:
                !hasGiftCard &&
                (typeof klarnaOrderMax == 'undefined' ||
                    !klarnaOrderMax ||
                    grandTotal < klarnaOrderMax),
            pay_over_time:
                !hasGiftCard &&
                (typeof klarnaOrderMax == 'undefined' ||
                    !klarnaOrderMax ||
                    grandTotal < klarnaOrderMax)
        });
    };

    /**
     * Get available payment methods
     */
    const [getPaymentMethodsQry, { loading: paymentLoading }] = useLazyQuery(
        getPaymentMethods,
        {
            fetchPolicy: 'no-cache',
            variables: {
                cartId: cartState.cartId,
                isSignedIn: !!cartState.cart.authenticated
            },
            onCompleted: res => {
                dispatch({
                    type: 'SET_CART',
                    cart: res.cart
                });
            }
        }
    );

    // Mutation to set Klarna payment info on the cart
    const [
        getKlarnaToken,
        { data: clientTokenData, loading: loadingToken }
    ] = useMutation(createKlarnaPaymentsSession, {
        variables: {
            cartId: cartState.cartId
        },
        onCompleted: res => {
            getPaymentMethodsQry();
        }
    });

    // Will use this to set Billing address if it is same as shipping address
    const [setBillingAddress] = useMutation(setBillingAddressOnCart, {
        variables: {
            cartId: cartState.cartId
        },
        onCompleted: res => {
            dispatch({
                type: 'SET_CART',
                cart: res.setBillingAddressOnCart.cart
            });
        }
    });

    // Mutation to placeorder
    const [placeOrder] = useMutation(placeOrderMutation, {
        variables: {
            cartId: cartState.cartId
        },
        onCompleted: res => {
            setPaymentProcessing(false);
            if (!!dataLayerAction) {
                dataLayerAction({
                    type: 'PLACE_ORDER',
                    data: {
                        ...cartState,
                        actionField: res
                    }
                });
            }
            dispatch({
                type: 'PLACED_ORDER',
                placedOrder: res
            });
        },
        onError: error => {
            setPaymentProcessing(false);
            setPaymentFailureError(true);
            let errorFlag = false;
            let errorMsg = null;

            if (error?.graphQLErrors) {
                for (var idx = 0; idx < error.graphQLErrors.length; idx++) {
                    if (
                        error.graphQLErrors[idx]?.extensions?.category ===
                        'graphql-input'
                    ) {
                        errorMsg = error.graphQLErrors[idx]?.message;
                        errorFlag = true;
                    }
                }
            }

            if (errorMsg && errorMsg) {
                setError(errorMsg);
            }
        }
    });

    // Mutation to set Klarna payment info on the cart
    const [setKlarnaPayment] = useMutation(setKlarnaPaymentMethod, {
        variables: {
            cartId: cartState.cartId
        }
    });

    /**
     * Klarna address format
     * @param {*} address
     */
    const getFormattedAddress = address => {
        return {
            street_address: deepValue(address, ['street', '0'], ''),
            street_address2: deepValue(address, ['street', '1'], ''),
            organization_name: '',
            phone: deepValue(address, ['telephone'], ''),
            postal_code: deepValue(address, ['postcode'], ''),
            region: deepValue(address, ['region', 'code'], ''),
            city: deepValue(address, ['city'], ''),
            country: deepValue(address, ['country', 'code'], ''),
            given_name: deepValue(address, ['firstname'], ''),
            family_name: deepValue(address, ['lastname'], '')
        };
    };

    /**
     * For klarna shipping address
     */
    const getShippingAddress = () => {
        const selectedShippingAddress = deepValue(
            cartState,
            ['cart', 'shipping_addresses', '0'],
            []
        );
        const formattedAddress = getFormattedAddress(selectedShippingAddress);
        return {
            email: cartState.cart.email,
            ...formattedAddress
        };
    };

    /**
     * Klarna billing address
     */
    const getBillingAddress = () => {
        let formattedAddress;
        if (cartState.cart.isBillingSameAsShipping) {
            formattedAddress = getShippingAddress();
        } else {
            let selectedBillingAddress = deepValue(
                cartState,
                ['cart', 'billing_address'],
                []
            );
            formattedAddress = getFormattedAddress(selectedBillingAddress);
        }
        return {
            email: cartState.cart.email,
            ...formattedAddress,
            organization_name: ''
        };
    };

    /**
     * Total applied discount amount
     * @param {*} item
     */
    const getDiscountAmount = item => {
        let discounts = deepValue(item, ['prices', 'discounts'], []);
        let appliedDiscount = 0;
        if (discounts) {
            discounts.map(discount => {
                appliedDiscount += discount.amount.value;
            });
        }
        return appliedDiscount;
    };

    const getShippingDiscount = () => {
        const discount = getDiscountOrderLine();
        let shippingDiscount = 0;
        if (discount && discount.total_amount) {
            shippingDiscount = Math.round(
                deepValue(
                    cartState,
                    ['cart', 'prices', 'subtotal_excluding_tax', 'value'],
                    0
                ) *
                    100 -
                    deepValue(
                        cartState,
                        [
                            'cart',
                            'prices',
                            'subtotal_with_discount_excluding_tax',
                            'value'
                        ],
                        0
                    ) *
                        100 +
                    discount.total_amount
            );
        }
        return shippingDiscount > 0 ? shippingDiscount : 0;
    };
    /**
     * Shipping option to klarna address line
     */
    const getShippingOption = () => {
        const shippingMethod = deepValue(
            cartState,
            ['cart', 'shipping_addresses', '0', 'selected_shipping_method'],
            []
        );
        return {
            type: 'shipping_fee',
            name: deepValue(shippingMethod, ['method_title'], ''),
            quantity: 1,
            unit_price: Math.round(
                deepValue(shippingMethod, ['amount', 'value'], 0) * 100
            ),
            total_amount: Math.round(
                deepValue(shippingMethod, ['amount', 'value'], 0) * 100
            ),
            total_discount_amount: getShippingDiscount()
        };
    };

    /**
     * Klarna address line discounts
     */
    const getDiscountOrderLine = () => {
        const prices = deepValue(cartState, ['cart', 'prices'], []);
        const appliedCoupons = deepValue(
            cartState,
            ['cart', 'applied_coupons'],
            []
        );
        const totalDiscount =
            deepValue(prices, ['subtotal_excluding_tax', 'value']) -
            deepValue(prices, [
                'subtotal_with_discount_excluding_tax',
                'value'
            ]);
        if (prices.discounts) {
            return {
                type: 'discount',
                name: deepValue(
                    prices,
                    ['discounts', '0', 'label'],
                    'discount'
                ),
                quantity: 1,
                unit_price: -Math.round(totalDiscount * 100),
                total_amount: -Math.round(totalDiscount * 100)
            };
        } else if (appliedCoupons && appliedCoupons.length) {
            return {
                type: 'discount',
                name: deepValue(appliedCoupons, ['0', 'code'], 'discount'),
                quantity: 1,
                unit_price: 0,
                total_amount: 0
            };
        }
        return null;
    };

    const getGiftCardOrderLine = () => {
        const appliedGiftCards = deepValue(
            cartState,
            ['cart', 'applied_gift_cards'],
            []
        );
        let appliedGiftCardAmount = 0;

        if (appliedGiftCards) {
            appliedGiftCards.map(appliedGiftCard => {
                appliedGiftCardAmount += deepValue(
                    appliedGiftCard,
                    ['applied_balance', 'value'],
                    0
                );
            });
        }

        if (appliedGiftCardAmount) {
            return {
                type: 'gift_card',
                name: deepValue(
                    cartState,
                    ['cart', 'applied_gift_cards', '0', 'code'],
                    'Gift card'
                ),
                quantity: 1,
                unit_price: -Math.round(appliedGiftCardAmount * 100),
                total_amount: -Math.round(appliedGiftCardAmount * 100)
            };
        }
        return null;
    };

    const getTaxOrderLine = () => {
        const displayPriceOption = deepValue(
            globalOptions,
            ['storeConfig', 'display_price_shopping_cart'],
            2
        );
        const appliedTaxes = deepValue(
            cartState,
            ['cart', 'prices', 'applied_taxes'],
            []
        );
        let totalTax = 0;

        appliedTaxes.map(appliedTax => {
            totalTax += deepValue(appliedTax, ['amount', 'value'], 0);
        });
        //For Klarna Payments (North America) sale_tax shall always be sent, even with zero amount
        return {
            type: 'sales_tax',
            name: deepValue(
                cartState,
                ['cart', 'prices', 'applied_taxes', '0', 'label'],
                'Tax'
            ),
            quantity: 1,
            unit_price: Math.round(totalTax * 100),
            total_amount: Math.round(totalTax * 100)
        };
    };

    const getProductItemPrice = item => {
        const displayPriceOption = deepValue(
            globalOptions,
            ['storeConfig', 'display_price_shopping_cart'],
            2
        );
        if (displayPriceOption == 1) {
            return deepValue(item, ['prices', 'row_total', 'value'], 0);
        } else {
            return deepValue(
                item,
                ['prices', 'row_total_including_tax', 'value'],
                0
            );
        }
    };

    const getCartItems = () => {
        let items = deepValue(cartState, ['cart', 'items'], []),
            cartItems = [],
            shippingFee = getShippingOption(),
            //discount = getDiscountOrderLine(),
            tax = getTaxOrderLine(),
            giftCard = getGiftCardOrderLine(),
            itemPrice = 0,
            itemDiscount = 0;

        items.map(item => {
            itemPrice = getProductItemPrice(item);
            itemDiscount = Math.round(
                deepValue(
                    item,
                    ['prices', 'discounts', '0', 'amount', 'value'],
                    0
                ) * 100
            );
            cartItems.push({
                name: deepValue(item, ['product', 'name'], ''),
                quantity: item.quantity,
                unit_price: Math.round((itemPrice / item.quantity) * 100),
                total_amount: Math.round(itemPrice * 100) - itemDiscount,
                total_discount_amount: itemDiscount
            });
        });

        //Add shipping to orderline
        if (shippingFee) {
            cartItems.push(shippingFee);
        }

        //Include discount to orderline
        /*if (discount) {
            cartItems.push(discount);
        }*/

        //Include tax
        if (tax) {
            cartItems.push(tax);
        }

        //Include
        if (giftCard) {
            cartItems.push(giftCard);
        }
        return cartItems;
    };

    const isPaymentCategoryAvailable = klarnaPaymentType => {
        const paymentCategories = deepValue(
            clientTokenData,
            ['createKlarnaPaymentsSession', 'payment_method_categories'],
            []
        );
        if (paymentCategories) {
            return paymentCategories.some(
                item => item.identifier === klarnaPaymentType
            );
        }
        return paymentCategories;
    };

    const noPaymentCategoryAvailable = () => {
        const paymentCategories = deepValue(
            clientTokenData,
            ['createKlarnaPaymentsSession', 'payment_method_categories'],
            []
        );
        return !paymentCategories || !paymentCategories.length;
    };

    const loadKlarnaWidget = paymentSession => {
        let paymentTitles = {};
        const cartPrices = deepValue(cartState, ['cart', 'prices'], []);

        if (paymentSession.payment_method_categories) {
            paymentSession.payment_method_categories.map(payment => {
                paymentTitles[payment.identifier] = payment.name;
                klarnaTypeAvailability[payment.identifier] &&
                    Klarna &&
                    Klarna?.Payments?.load(
                        {
                            container: `#klarna-payments-container-${payment.identifier}`,
                            payment_method_category: payment.identifier
                        },
                        {
                            purchase_country:
                                window.STORE_CODE == 'uk' ? 'GB' : 'US',
                            purchase_currency: deepValue(
                                cartPrices,
                                ['grand_total', 'currency'],
                                'GBP'
                            ),
                            locale:
                                window.STORE_CODE == 'uk' ? 'en-GB' : 'en-US',
                            billing_address: getBillingAddress(),
                            shipping_address: getShippingAddress(),
                            order_amount: Math.round(
                                deepValue(
                                    cartPrices,
                                    ['grand_total', 'value'],
                                    0
                                ) * 100
                            ),
                            order_lines: getCartItems()
                        },
                        function(res) {
                            // console.warn(res);
                        }
                    );
            });
        }

        setKlarnaTypeNames(paymentTitles);
    };

    useEffect(() => {
        if (paymentProcessing) {
            document.body.classList.add('payment-processing');
        } else {
            document.body.classList.remove('payment-processing');
        }
    }, [paymentProcessing]);

    useEffect(() => {
        (async () => {
            if (!clientTokenData) {
                await getKlarnaToken();
            } else {
                window.klarnaAsyncCallback = function() {
                    const {
                        createKlarnaPaymentsSession: paymentSession
                    } = clientTokenData;

                    try {
                        //Initialise klarna
                        Klarna.Payments.init({
                            client_token: paymentSession.client_token
                        });

                        //Get available payment method will fetch the latest status from Klarna
                        //Then render the Klarna payment method based on the response
                        getPaymentMethodsQry();
                    } catch (e) {
                        console.warn(e);
                    }
                };
                //Load klarna sdk
                klarnaHelper.injectScipt();
            }
        })();
    }, [clientTokenData]);

    useEffect(() => {
        isKlarnaTypeDisabled();
        if (
            typeof Klarna !== 'undefined' &&
            clientTokenData &&
            !paymentProcessing
        ) {
            const {
                createKlarnaPaymentsSession: paymentSession
            } = clientTokenData;
            loadKlarnaWidget(paymentSession);
        }
    }, [cartState.cart]);

    const datalayerCheckoutOption = () => {
        dataLayerAction({
            type: 'CHECKOUT_OPTION',
            data: {
                step: 2,
                option: 'Klarna'
            }
        });
    };

    const handlePlaceOrder = () => {
        setPaymentFailureError(false);
        // if the user wants to place order with the billing address the same as the shipping address
        // otherwise he/she submitted a billing address
        setBillingAddress({
            variables: cartState.cart.isBillingSameAsShipping
                ? {
                      cartId: cartState.cartId,
                      ...cartState.cart.shipping_addresses[0],
                      region: cartState.cart.shipping_addresses[0].region.code,
                      countryCode:
                          cartState.cart.shipping_addresses[0].country.code
                  }
                : {
                      cartId: cartState.cartId,
                      ...cartState.cart.billing_address,
                      region: cartState.cart.billing_address.region.code,
                      countryCode: cartState.cart.billing_address.country.code
                  }
        })
            .then(res => {
                if (res) {
                    if (!!dataLayerAction) {
                        datalayerCheckoutOption();
                    }
                    placeOrder();
                }
            })
            .catch(e => {
                console.log('error in setBillingAddress', e);

                setPaymentProcessing(false);
            });
    };

    const handleSelect = event => {
        setKlarnaType(event.target.value);
    };

    const isLoadingInProgress = loadingToken || paymentLoading;

    //if (loadingToken || paymentLoading) return <LoadingIndicator />;

    if (!isLoadingInProgress && !clientTokenData) {
        return (
            <div>
                {' '}
                Something went wrong. Please choose a different payment method.
            </div>
        );
    }

    if (!isLoadingInProgress && noPaymentCategoryAvailable())
        return (
            <div>
                {' '}
                Unfortunately this payment method is not available for the
                selected shipping/billing country. Please choose a different
                payment method.
            </div>
        );

    return (
        <div
            className={`klarna-payment ${paymentProcessing ? ' loading' : ''}`}
        >
            {isLoadingInProgress && <LoadingIndicator />}
            {error && <PaymentFailure />}

            {isPaymentCategoryAvailable('pay_later') && (
                <div
                    className={`klarna-pay_later ${
                        !klarnaTypeAvailability['pay_later'] ? 'disabled' : ''
                    }`}
                >
                    {!isLoadingInProgress && klarnaTypeNames.pay_later && (
                        <div className={'root-radio-group'}>
                            <label
                                className={'payment-method'}
                                key={'pay_later'}
                            >
                                <input
                                    className={'input-radio'}
                                    type="radio"
                                    name={'klarna'}
                                    value={'pay_later'}
                                    onChange={handleSelect}
                                    disabled={
                                        !klarnaTypeAvailability['pay_later']
                                    }
                                />
                                <img
                                    width="55"
                                    src={klarnaHelper.getKlarnaLogo()}
                                ></img>
                                <span className={'payment-title'}>
                                    {klarnaTypeNames.pay_later}
                                    <span
                                        className={`klarna-disabled-msg ${
                                            !klarnaTypeAvailability['pay_later']
                                                ? 'disabled'
                                                : ''
                                        }`}
                                    >
                                        {klarnaHelper.getKlarnaDisabledMessage(
                                            klarnaConfig
                                        )}
                                    </span>
                                </span>
                            </label>
                        </div>
                    )}

                    <KlarnaPayLater
                        setKlarnaPayment={setKlarnaPayment}
                        handlePlaceOrder={handlePlaceOrder}
                        buttonState={buttonState}
                        klarnaType={klarnaType}
                        getBillingAddress={getBillingAddress}
                        getDiscountAmount={getDiscountAmount}
                        getShippingOption={getShippingOption}
                        getDiscountOrderLine={getDiscountOrderLine}
                        getCartItems={getCartItems}
                        getShippingAddress={getShippingAddress}
                        clientTokenData={clientTokenData}
                        setPaymentProcessing={setPaymentProcessing}
                        PrivacyPolicy={PrivacyPolicy}
                        privacyPolicyLink={privacyPolicyLink}
                        paymentProcessing={paymentProcessing}
                        NewsletterCheckbox={NewsletterCheckbox}
                    />
                </div>
            )}

            {isPaymentCategoryAvailable('pay_over_time') && (
                <div
                    className={`klarna-pay_over_time ${
                        !klarnaTypeAvailability['pay_over_time']
                            ? 'disabled'
                            : ''
                    }`}
                >
                    {!isLoadingInProgress && klarnaTypeNames.pay_over_time && (
                        <div className={'root-radio-group'}>
                            <label
                                className={'payment-method'}
                                key={'pay_over_time'}
                            >
                                <input
                                    className={'input-radio'}
                                    type="radio"
                                    name={'klarna'}
                                    value={'pay_over_time'}
                                    onChange={handleSelect}
                                    disabled={
                                        !klarnaTypeAvailability['pay_over_time']
                                    }
                                />
                                <img
                                    width="55"
                                    src={klarnaHelper.getKlarnaLogo()}
                                ></img>
                                <span className={'payment-title'}>
                                    {`Pay with ${
                                        window.STORE_CODE == 'uk'
                                            ? '3-interest free instalments'
                                            : '4-interest free installments'
                                    }`}
                                    <span
                                        className={`klarna-disabled-msg ${
                                            !klarnaTypeAvailability[
                                                'pay_over_time'
                                            ]
                                                ? 'disabled'
                                                : ''
                                        }`}
                                    >
                                        {klarnaHelper.getKlarnaDisabledMessage(
                                            klarnaConfig
                                        )}
                                    </span>
                                </span>
                            </label>
                        </div>
                    )}

                    <KlarnaPayOverTime
                        setKlarnaPayment={setKlarnaPayment}
                        handlePlaceOrder={handlePlaceOrder}
                        buttonState={buttonState}
                        klarnaType={klarnaType}
                        getBillingAddress={getBillingAddress}
                        getShippingAddress={getShippingAddress}
                        getDiscountAmount={getDiscountAmount}
                        getShippingOption={getShippingOption}
                        getDiscountOrderLine={getDiscountOrderLine}
                        getCartItems={getCartItems}
                        clientTokenData={clientTokenData}
                        setPaymentProcessing={setPaymentProcessing}
                        PrivacyPolicy={PrivacyPolicy}
                        privacyPolicyLink={privacyPolicyLink}
                        paymentProcessing={paymentProcessing}
                        NewsletterCheckbox={NewsletterCheckbox}
                    />
                </div>
            )}
        </div>
    );
};

KlarnaPayment.defaultProps = {
    PrivacyPolicy: null,
    privacyPolicyLink: '#',
    setPaymentFailureError: () => {}
};

export default KlarnaPayment;
