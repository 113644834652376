/** @jsx jsx */
import React, { useState, useContext, Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { object, string, bool, number, func } from 'prop-types';
import { jsx } from '@emotion/core';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import {
    CartStore,
    useGlobalOptions,
    useSetNotification
} from '@corratech/context-provider';
import { RenderApolloError } from '@corratech/render-apollo-error';
import { QuantitySelector } from '@corratech/quantity-selector';
import removeItemFromCart from '../queries/removeItemFromCart.graphql';
import updateCartItems from '../queries/updateCartItems.graphql';
import { getCurrencySymbol, setPriceZeroes } from '../util';
import { CartItemOptions } from '../CartItemOptions/CartItemOptions';
import { ModalConfirmation } from '../ModalConfirmation/ModalConfirmation';
import { EditIcon, RemoveIcon } from '../Icons';
import { MoveCartItemToWishlist } from '../MoveCartItemToWishlist/MoveCartItemToWishlist';
import { AlertCircle } from 'react-feather';
import { useDataLayerAction } from '@corratech/tag-manager';
import { GiftCardDetails } from 'ModulesPath/Cart/GiftCard';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import { orderGrooveOptins, getTrackingOverride } from 'UtilPath/orderGroove';
import { Button, Modal } from 'react-bootstrap';
import applyCouponToCart from 'ModulesPath/App/Queries/applyCouponToCart.graphql';

require('./CartItem.less');

export const CartItem = props => {
    const {
        item,
        imageWidth,
        currencyCode,
        className,
        css,
        hasPopup,
        canEdit,
        disableControls,
        shoppingCart,
        setIsEditingItem,
        setEditItem,
        hasConfirmation,
        qtyLoaderProps,
        enableWishlist,
        setWishlistMessage,
        displayAsShoppingCart,
        removeItemFromCartGraphql,
        updateCartItemsGraphql,
        setQtyUpdating
    } = props;

    const [t] = useTranslation();

    const options = useGlobalOptions();
    const { setNotification, clearNotification } = useSetNotification();
    const storeConfig = options.storeConfig || {};
    const includeTaxItemPrice =
        storeConfig.display_price_shopping_cart === '2' ? true : false;

    const [title, setTitle] = useState(`Promo code applied`);

    const urlSuffix =
        typeof options['urlSuffix'] === 'undefined' || options['urlSuffix']
            ? '.html'
            : '';

    const [show, setShow] = useState(false);
    const [showCouponModal, setShowCouponModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies(['coupon_code']);

    const imagePrefix = `?auto=webp&optimize=high&format=pjpg&crop=1:1&width=${imageWidth}`;

    const currencySymbol = currencyCode
        ? getCurrencySymbol(currencyCode)
        : null;

    const handleClose = () => {
        setShow(false);
    };

    const stockStatus = item.product.stock_status;

    const handleEditItem = event => {
        if (event) event.preventDefault();
        event.nativeEvent.stopImmediatePropagation();

        setEditItem(item);
        setIsEditingItem(true);
    };

    const handleShow = event => {
        if (event) event.preventDefault();
        setShow(true);
    };

    const { cartState, dispatch } = useContext(CartStore);
    const dataLayerAction = useDataLayerAction();
    let location = useLocation();
    const isCO = () => {
        return location.pathname.indexOf('/checkout') == 0;
    };

    const [removeItem, { loading: removeLoading }] = useMutation(
        removeItemFromCartGraphql,
        {
            variables: {
                cartId: cartState.cartId,
                itemId: parseInt(item.id)
            },
            onCompleted: res => {
                trackItemQuantityChange(res);
                setShow(false);
                dispatch({
                    type: 'SET_CART',
                    cart: res.removeItemFromCart.cart
                });
            },
            onError: error => {
                setNotification(
                    'danger',
                    t(props.removeErrorText),
                    3600 * 1000 * 24
                );
                throw new Error(error.message.split('GraphQL error: ')[1]);
            }
        }
    );

    const [applyCoupon] = useMutation(applyCouponToCart, {
        variables: {
            cartId: cartState.cartId,
            isSignedIn: !!cartState.cart.authenticated
        },
        onCompleted: res => {
            dispatch({
                type: 'SET_CART',
                cart: res.applyCouponToCart.cart
            });
            const couponCode = cookies.coupon_code;
            //Reset Promo error on valid execution
            removeCookie('coupon_code');
            setModalMessage(
                `Your promo code ${couponCode} has been applied successfully`
            );
            setShowCouponModal(true);
        },
        onError: res => {
            removeCookie('coupon_code');
            if (
                res?.graphQLErrors[0]?.extensions &&
                res?.graphQLErrors[0]?.extensions?.category ===
                    'graphql-no-such-entity'
            ) {
                setModalMessage(res?.graphQLErrors[0]?.message);
                setShowCouponModal(true);
                setTitle('Apply Promo');
            }
        }
    });

    const handleCouponModal = () => {
        setShowCouponModal(false);
    };

    const addCookieCouponToCart = () => {
        const couponCode = cookies.coupon_code;
        if (couponCode) {
            applyCoupon({
                variables: {
                    couponCode
                }
            });
        }
    };

    let subscriptionID = item.product.id;
    if (item.product.__typename === 'ConfigurableProduct') {
        subscriptionID = item.product.variants?.find(
            cartItem => cartItem?.product?.sku === item.itemsku
        ).product.id || subscriptionID;
    }

    const [
        updateItemQuantity,
        { error: updateError, loading: loadingQuantity }
    ] = useMutation(updateCartItemsGraphql, {
        fetchPolicy: 'no-cache',
        variables: {
            cartId: cartState.cartId,
            itemId: parseInt(item.id),
            isSubscription: orderGrooveOptins(subscriptionID.toString()).length > 0,
            ogOptions: getTrackingOverride(subscriptionID.toString())
        },
        onCompleted: res => {
            trackItemQuantityChange(res);
            const updatedCart = cartState.cart;
            updatedCart.items = res.updateCartItems.cart.items;
            updatedCart.prices = res.updateCartItems.cart.prices;
            if (res.updateCartItems.cart.free_items)
                updatedCart.free_items = res.updateCartItems.cart.free_items;
            if (res.updateCartItems.cart.total_quantity)
                updatedCart.total_quantity =
                    res.updateCartItems.cart.total_quantity;
            updatedCart.applied_coupons =
                res.updateCartItems.cart.applied_coupons;
            updatedCart.is_virtual = res.updateCartItems.cart.is_virtual;
            dispatch({
                type: 'SET_CART',
                cart: updatedCart
            });
            addCookieCouponToCart();
        },
        onError: res => {
            console.warn(res);
        }
    });

    const trackItemQuantityChange = queryResult => {
        const oldQty = parseInt(item.quantity);
        let newQty = 0;
        const cartData =
            queryResult.removeItemFromCart || queryResult.updateCartItems;
        cartData.cart.items.map(updatedItem => {
            if (item.id === updatedItem.id) {
                newQty = parseInt(updatedItem.quantity);
            }
        });
        dataLayerAction({
            type: oldQty > newQty ? 'REMOVE_TO_CART' : 'ADD_TO_CART',
            data: {
                ...item,
                ...cartData,
                quantityChange: Math.abs(oldQty - newQty)
            }
        });
    };

    const setItemQuantity = quantity => {
        if (quantity !== '' && !isNaN(quantity)) {
            if (quantity > 0) {
                updateItemQuantity({
                    variables: {
                        quantity
                    }
                });
            } else {
                removeItem();
            }
        }
    };

    /**
     * Keeping both if and else case as fallback while upgrading
     * @return {*}
     */
    const renderPriceCalculation = () => {
        const totalDiscount = item.prices?.discounts?.reduce((acc, discount) => acc + discount.amount.value, 0) || 0;
        const discountTaxCompensationAmount = item?.discount_tax_compensation_amount || 0;

        if (!item.prices) return null;

        const rowTotalCost = includeTaxItemPrice
            ? item.prices.row_total_including_tax?.value
            : item.prices.row_total?.value;

        const totalItemCost = rowTotalCost
            ? rowTotalCost
            : (parseFloat(item.prices.price.value) * parseFloat(item.quantity)).toFixed(2);

        const basePrice = (parseFloat(rowTotalCost).toFixed(2) || 0) - totalDiscount;
        const adjustedTotal = (parseFloat(includeTaxItemPrice ? basePrice : basePrice + discountTaxCompensationAmount).toFixed(2));

        return (
            <Fragment>
                 <span className={`cart-item-price ${totalDiscount > 0 ? 'cart-item-price-full' : ''}`}>
                    {currencySymbol + setPriceZeroes(totalItemCost || 0)}
                </span>
                {totalDiscount > 0 && (
                    <span className="cart-item-price">
                    {currencySymbol + setPriceZeroes(adjustedTotal)}
                </span>
                )}
            </Fragment>
        );
    };

    /**
     * Keeping both if and else case as fallback while upgrading
     * @return {*}
     */
    const renderPriceCalculationShoppingCart = () => {
        const totalDiscount = item.prices?.discounts?.reduce((acc, discount) => acc + discount.amount.value, 0) || 0;
        const discountTaxCompensationAmount = item?.discount_tax_compensation_amount || 0;

        if (!item.prices) return null;

        const rowTotalCost = includeTaxItemPrice
            ? item.prices.row_total_including_tax?.value
            : item.prices.row_total?.value;

        const itemPrice = rowTotalCost
            ? (parseFloat(rowTotalCost) / parseFloat(item.quantity)).toFixed(2)
            : (parseFloat(item.prices.price.value) * parseFloat(item.quantity)).toFixed(2);

        const basePrice = (parseFloat(rowTotalCost).toFixed(2) || 0) - totalDiscount;
        const adjustedTotal = (parseFloat(includeTaxItemPrice ? basePrice : basePrice + discountTaxCompensationAmount).toFixed(2));

        return (
            <div>
                <span className={`cart-item-subtotal ${totalDiscount > 0 ? 'cart-item-subtotal-full' : ''}`} data-label="Subtotal">
                    {currencySymbol + setPriceZeroes(rowTotalCost)}
                </span>
                {totalDiscount > 0 && (
                    <span className="cart-item-subtotal">
                    {currencySymbol + setPriceZeroes(adjustedTotal)}
                </span>
                )}
                {!displayAsShoppingCart && (
                    <div className="cart-item-price" data-label={t('Price')}>
                        {currencySymbol + itemPrice}
                    </div>
                )}
            </div>
        );
    };

    const isFreeItem = () => {
        let freeClass = '';
        if (
            cartState.cart.free_items &&
            cartState.cart.free_items.free_gifts.length > 0
        ) {
            cartState.cart.free_items.free_gifts.map(freeitem => {
                if (
                    freeitem.sku == item.product.sku &&
                    parseFloat(item.prices.price.value) == 0
                ) {
                    freeClass = 'free';
                }
            });
        }
        return freeClass;
    };

    const isFreeSample = () => {
        let freeClass = '';
        if (
            cartState.cart.free_items &&
            cartState.cart.free_items.free_samples.length > 0
        ) {
            cartState.cart.free_items.free_samples.map(freesample => {
                if (
                    freesample.sku == item.product.sku &&
                    parseFloat(item.prices.price.value) == 0
                ) {
                    freeClass = 'free-sample';
                }
            });
        }
        return freeClass;
    };

    useEffect(() => {
        if (loadingQuantity || removeLoading) {
            setQtyUpdating(true);
        } else {
            setQtyUpdating(false);
        }
    }, [loadingQuantity, removeLoading]);

    const cartItemOptions = (() => {
        switch (item.__typename) {
            case 'BundleCartItem':
                return item.bundle_options;
            case 'SimpleCartItem':
                return item.product.bottle_size_text;
            default:
                return item.configurable_options;
        }
    })();

    return (
        <Fragment>
            {showCouponModal && (
                <Modal
                    show={showCouponModal}
                    onHide={handleCouponModal}
                    className="loqate-address-suggestion-popup"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>{modalMessage}</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCouponModal}>
                            {'Ok'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
            <div
                className={
                    `cart-item ${isFreeItem()} ${isFreeSample()}` +
                    (className || '')
                }
                css={css}
            >
                <div
                    className={
                        cartState.cart.free_items &&
                        cartState.cart.free_items.free_samples.length > 0
                            ? 'cart-item-wrapper'
                            : 'cart-item-wrapper no-samples'
                    }
                >
                    {hasConfirmation && show ? (
                        <ModalConfirmation
                            handleClose={handleClose}
                            removeItem={removeItem}
                            show={show}
                        />
                    ) : null}
                    <div className={'cart-item-image'}>
                        <Link to={`/${item.product.url_key}${urlSuffix}`}>
                            {item.product.thumbnail ? (
                                <img
                                    src={
                                        item.product.thumbnail.url + imagePrefix
                                    }
                                    alt={item.product.name}
                                />
                            ) : null}
                        </Link>
                    </div>
                    <div className={'cart-item-details'}>
                        <div className={'cart-item-info'}>
                            <div className={'cart-item-name'}>
                                <Link
                                    to={`/${item.product.url_key}${urlSuffix}`}
                                >
                                    {item.product.name}
                                </Link>
                            </div>
                            <CartItemOptions
                                options={cartItemOptions}
                                hideOptions={
                                    item.exploding_kit
                                        ? Number(item.exploding_kit)
                                        : false
                                }
                                productType={item.__typename}
                            />
                            <div className={'cart-item-sku'}>
                                {t('SKU')}: {item.product.sku}
                            </div>
                            {item.preorder == '1' &&
                            item.preorder != 'undefined' ? (
                                <div className={'cart-item-preorder'}>
                                    {t('Pre-Order')}: {item.preorder_note}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        {storeConfig?.ordergroove_subscription_general_public_id &&
                            !isCO() && (
                                <og-offer
                                    product={
                                        item.product.__typename ===
                                        'ConfigurableProduct'
                                            ? item.product?.variants?.find(
                                                  cartItem =>
                                                      cartItem?.product?.sku ===
                                                      item.itemsku
                                              ).product.id
                                            : item.product.id
                                    }
                                    data-line-id={item.id}
                                    data-line-qty={item.quantity}
                                    location="cart"
                                ></og-offer>
                            )}
                        {!displayAsShoppingCart && (
                            <div className={'cart-item-actions'}>
                                {enableWishlist ? (
                                    <MoveCartItemToWishlist
                                        item={item}
                                        setMessage={setWishlistMessage}
                                    />
                                ) : null}

                                {canEdit && (
                                    <div className={'cart-item-action'}>
                                        <span className={'cart-item-edit'}>
                                            <Link
                                                to={'#'}
                                                title={t('Edit')}
                                                onClick={handleEditItem}
                                            >
                                                <EditIcon
                                                    size={10}
                                                    className={'edit-icon'}
                                                />
                                                {t('Edit')}
                                            </Link>
                                        </span>
                                    </div>
                                )}

                                {!disableControls && (
                                    <div className={'cart-item-action'}>
                                        <span className={'cart-item-remove'}>
                                            <Link
                                                to={'#'}
                                                title={t('Remove')}
                                                className={
                                                    removeLoading
                                                        ? 'disabled'
                                                        : ''
                                                }
                                                onClick={
                                                    hasConfirmation
                                                        ? handleShow
                                                        : removeItem
                                                }
                                            >
                                                <RemoveIcon
                                                    size={12}
                                                    strokeWidth={3}
                                                    className={'remove-icon'}
                                                />
                                                {removeLoading
                                                    ? t('Removing')
                                                    : t('Remove')}
                                            </Link>
                                        </span>
                                    </div>
                                )}
                                {item.product.stock_status ===
                                    'OUT_OF_STOCK' && (
                                    <Link
                                        to={`/${item.product.url_key}${urlSuffix}`}
                                    >
                                        <span
                                            className={
                                                'cart-item-stock-message'
                                            }
                                        >
                                            <AlertCircle size={18} />{' '}
                                            {t('Out of Stock')}
                                        </span>
                                    </Link>
                                )}
                            </div>
                        )}
                        <div className={'cart-item-data'}>
                            <div className={'cart-item-data-inner'}>
                                {displayAsShoppingCart && (
                                    <Fragment>
                                        {renderPriceCalculationShoppingCart()}
                                        <div className="item-instock-sec">
                                            {stockStatus == 'IN_STOCK' && (
                                                <Fragment>
                                                    <span className="item-status">
                                                        {t('In Stock')}
                                                    </span>
                                                    <span className="item-ship">
                                                        {t('Ready to Ship')}
                                                    </span>
                                                </Fragment>
                                            )}
                                        </div>
                                    </Fragment>
                                )}
                                <div
                                    className={'cart-item-qty'}
                                    data-label={t('Qty')}
                                >
                                    {!disableControls ? (
                                        <Fragment>
                                            {displayAsShoppingCart && (
                                                <label
                                                    className="qty-label"
                                                    htmlFor="qty"
                                                >
                                                    {t('Qty')}
                                                </label>
                                            )}
                                            <QuantitySelector
                                                setQuantity={setItemQuantity}
                                                quantity={item.quantity}
                                                loading={loadingQuantity}
                                                placeholder={'1'}
                                                showRemovePopup={handleShow}
                                                updateError={updateError}
                                            />
                                        </Fragment>
                                    ) : (
                                        <span>
                                            {t('Qty')}: {item.quantity}
                                        </span>
                                    )}
                                </div>
                                <div className={'price-variant-wrap'}>
                                    {!displayAsShoppingCart && (
                                        <Fragment>
                                            {!shoppingCart ? (
                                                <div
                                                    className={
                                                        'cart-item-price-wrapper'
                                                    }
                                                >
                                                    {renderPriceCalculation()}
                                                </div>
                                            ) : (
                                                renderPriceCalculationShoppingCart()
                                            )}
                                        </Fragment>
                                    )}
                                </div>
                                {item.__typename === 'GiftCardCartItem' &&
                                    item.giftcard_options &&
                                    item.giftcard_options[0] && (
                                        <GiftCardDetails
                                            giftCardOptions={
                                                item.giftcard_options[0]
                                            }
                                        />
                                    )}
                            </div>
                        </div>
                    </div>

                    {displayAsShoppingCart && (
                        <div className={'cart-item-actions'}>
                            {enableWishlist ? (
                                <MoveCartItemToWishlist
                                    item={item}
                                    setMessage={setWishlistMessage}
                                />
                            ) : null}

                            {canEdit && (
                                <div className={'cart-item-action'}>
                                    <span className={'cart-item-edit'}>
                                        <Link
                                            to={'#'}
                                            title={t('Edit')}
                                            onClick={handleEditItem}
                                        >
                                            <EditIcon
                                                size={10}
                                                className={'edit-icon'}
                                            />
                                            {t('Edit')}
                                        </Link>
                                    </span>
                                </div>
                            )}

                            {!disableControls && (
                                <div className={'cart-item-action'}>
                                    <span className={'cart-item-remove'}>
                                        <Link
                                            to={'#'}
                                            title={t('Remove')}
                                            className={
                                                removeLoading ? 'disabled' : ''
                                            }
                                            onClick={
                                                hasConfirmation
                                                    ? handleShow
                                                    : removeItem
                                            }
                                        >
                                            <RemoveIcon
                                                size={12}
                                                strokeWidth={3}
                                                className={'remove-icon'}
                                            />
                                            {removeLoading
                                                ? t('Removing')
                                                : t('Remove')}
                                        </Link>
                                    </span>
                                </div>
                            )}
                            {item.product.stock_status === 'OUT_OF_STOCK' && (
                                <Link
                                    to={`/${item.product.url_key}${urlSuffix}`}
                                >
                                    <span className={'cart-item-stock-message'}>
                                        <AlertCircle size={18} /> Out of Stock
                                    </span>
                                </Link>
                            )}
                        </div>
                    )}
                </div>
                {updateError ? <RenderApolloError error={updateError} /> : null}
            </div>
        </Fragment>
    );
};

CartItem.propTypes = {
    className: string,
    css: object,
    currencyCode: string,
    disableControls: bool,
    item: object,
    shoppingCart: bool,
    saleText: string,
    canEdit: bool,
    imageWidth: number,
    hasConfirmation: bool,
    setIsEditingItem: func,
    setEditItem: func,
    qtyLoaderProps: object,
    enableWishlist: bool,
    setWishlistMessage: func,
    displayAsShoppingCart: bool
};

CartItem.defaultProps = {
    saleText: 'Sale!',
    canEdit: true,
    hasConfirmation: false,
    imageWidth: 200,
    disableControls: false,
    displayAsShoppingCart: false,
    removeItemFromCartGraphql: removeItemFromCart,
    updateCartItemsGraphql: updateCartItems,
    removeErrorText: 'Could not remove item from cart. Please try again.',
    setQtyUpdating: () => {}
};
