import React from 'react';
import { Helmet } from 'react-helmet-async';
import { getFormattedBreadcrumbs } from './getFormattedBreadcrumbs';

export const Breadcrumbs = props => {
    const {
        amasty_breadcrumbs_data,
        urlBase,
        model,
        getStoreConfig,
        config: { breadcrumbs_enabled },
        pageBreadcrumbs
    } = props;

    let breadcrumbsData = null;

    if (pageBreadcrumbs) {
        let processeBreadcrumbs = [
            {
                '@type': 'ListItem',
                position: 1,
                item: {
                    '@id': urlBase,
                    name: 'Home'
                }
            }
        ];

        processeBreadcrumbs = processeBreadcrumbs.concat(pageBreadcrumbs);
        breadcrumbsData = {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: processeBreadcrumbs
        };
    }
    const breadcrumbData = breadcrumbsData || amasty_breadcrumbs_data;

    if (breadcrumbData) {
        return (
            <Helmet>
                <script type={'application/ld+json'}>
                    {JSON.stringify(breadcrumbData, null, 2)}
                </script>
            </Helmet>
        );
    }

    // Required options. Currently breadcrumbs are rendered on PLP and PDP.
    if (!breadcrumbs_enabled || !model) {
        return '';
    }

    const breadcrumbs = getFormattedBreadcrumbs(
        model,
        getStoreConfig('product_url_suffix', ''),
        getStoreConfig('category_url_suffix', '')
    );
    const breadcrumbItems = [];
    breadcrumbs.map((breadcrumb, index) => {
        let { position, name, url_key } = breadcrumb;
        breadcrumbItems.push({
            '@type': 'ListItem',
            position: position,
            name: name,
            item: `${urlBase}${url_key}`
        });
    });

    const jsonData = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbItems
    };

    return (
        <Helmet>
            <script type={'application/ld+json'}>
                {JSON.stringify(jsonData, null, 2)}
            </script>
        </Helmet>
    );
};
