import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useScript } from '@corratech/bll/script';
import GET_PRODUCTS_BY_SKU from 'ModulesPath/Catalog/PLP/getProductsBySku.graphql';
import { useLazyQuery } from 'react-apollo';
import classNames from 'classnames/bind';
import defaultClasses from 'ModulesPath/Catalog/ProductList/ProductList.less';
import { useGlobalOptions, LoaderStore } from '@corratech/context-provider';
import { useTranslation } from 'react-i18next';
import Product from './Product';
import EmailCollectionForm from './EmailCollectionForm';

const style = classNames.bind(defaultClasses);

const Mobile = () => {
    const LoadingIndicator = useContext(LoaderStore);
    const [ymkSkus, setYmkSkus] = useState([]);
    const [ymkData, setYmkData] = useState(null);
    const [productsData, setProductsData] = useState(null);
    const [routineData, setRoutineData] = useState(null);
    const [allProductsData, setAllProductsData] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [allCategoryData, setAllCategoryData] = useState(null);
    const [categoryData, setCategoryData] = useState(null);
    const [routineCategoryData, setRoutineCategoryData] = useState(null);

    const [filter, setFilter] = useState('');
    const [t] = useTranslation();

    const defaultFilter = 'full';
    const defaultCategory = 'Full Skin Regimen';
    const categoryMapping = {
        wrinkles: 'Wrinkles',
        spots: 'Spots',
        texture: 'Texture',
        redness: 'Redness',
        firmness: 'Firmness',
        pore: 'Pores'
    };

    const concerns = {
        texture: 'Texture',
        pore: 'Oil & Pores',
        spots: 'Dark Spots',
        redness: 'Redness',
        firmness: 'Firmness',
        wrinkles: 'Wrinkles'
    };

    const categoryOrder = [
        'CLEANSE',
        'TONE/ESSENCE',
        'EYE',
        'TREAT',
        'DAY CREAM',
        'NIGHT CREAM',
        'WEEKLY'
    ];

    const options = useGlobalOptions();
    const apiURL = options?.storeConfig?.perfect_corp_api_url;
    const privacyPolicyURL = `${options?.storeConfig?.base_url}privacy-policy`;

    useScript(apiURL);

    // fetch products
    const [getProductsBySku] = useLazyQuery(GET_PRODUCTS_BY_SKU, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            setAllProductsData(data?.products?.items);
            setFilter(defaultFilter);
        },
        onError: () => {
            setLoading(false);
        }
    });

    const filterProduct = filter => {
        const productSku = ymkData?.recommendation?.products
            ?.filter(item => item?.sources?.includes(filter))
            ?.map(item => item?.upc);
        return allProductsData.filter(item => productSku?.includes(item.sku));
    };

    const filterCategoryData = filter => {
        if (filter == defaultFilter) {
            return allCategoryData?.[defaultCategory];
        }
        const concernCategory = allCategoryData?.['Priority Purchases'];
        return concernCategory?.[categoryMapping[filter]];
    };

    const filterCategory = (sku, data) => {
        return data?.find(category => category?.['SKU Id'] == sku);
    };

    useEffect(() => {
        if (allProductsData && ymkData) {
            const sortedProduct = sortProducts(
                filterCategoryData(filter),
                filterProduct(filter)
            );
            setProductsData(sortedProduct);
            // set category data
            setCategoryData(filterCategoryData(filter));
            if (filter !== defaultFilter) {
                const sortRoutineProducts = sortProducts(
                    allCategoryData?.['Complete Your Routine'],
                    filterProduct('complete')
                );
                setRoutineData(sortRoutineProducts);
                // set routine category data
                setRoutineCategoryData(
                    allCategoryData?.['Complete Your Routine']
                );
            }
        }
    }, [filter, allProductsData?.length]);

    const sortProducts = (categoryData, productData) => {
        const skuOrder = categoryOrder.map(order => {
            return categoryData?.find(category => category.Category == order)?.[
                'SKU Id'
            ];
        });

        const productOrder = productData.sort(
            (a, b) => skuOrder.indexOf(a.sku) - skuOrder.indexOf(b.sku)
        );
        return productOrder.filter(item => item);
    };

    useEffect(() => {
        if (ymkSkus?.length && !allProductsData) {
            getProductsBySku({
                variables: {
                    skus: ymkSkus
                }
            });
        }
    }, [ymkSkus?.length]);

    useEffect(() => {
        window.ymkAsyncInit = () => {
            YMK.init({
                autoOpen: false,
                language: 'enu',
                width: '100%',
                height: window.screen.height > 736 ? 736 : window.screen.height,
                privacyPolicyURL: privacyPolicyURL
            });

            YMK.openSkincare();

            YMK.addEventListener('opened', function() {
                setLoading(false);
            });

            YMK.addEventListener('closed', function() {
                setLoading(true);
                YMK.openSkincare();
                setProductsData(null);
                setRoutineData(null);
                setAllProductsData(null);
                setYmkSkus([]);
            });

            YMK.addEventListener('skinAnalysisUpdated', function(data) {
                const skus = data?.recommendation?.products?.map(
                    product => product?.upc
                );
                setYmkData(data);
                setYmkSkus(skus);
                const cmsDiv = document.getElementById('cms-page-content');
                cmsDiv.style.display = 'none';
            });

            YMK.addEventListener('skincareProductRecommendation', function(
                data
            ) {
                setAllCategoryData(data);
            });

            YMK.addEventListener('skinAnalysisParameterChanged', function(
                report
            ) {
                let filterType = report;
                if (filterType == 'all') {
                    filterType = defaultFilter;
                } else if (filterType == 'ageSpots') {
                    filterType = 'spots';
                }
                setFilter(filterType);
            });
        };

        if (typeof YMK != 'undefined') {
            window.ymkAsyncInit();
        }
    }, []);

    const setDefaultFilter = useCallback(() => {
        if (filter == defaultFilter) {
            return;
        }
        YMK.setSkincareResultType('all');
    }, [filter]);

    useEffect(() => {
        document.body.classList.add('pc-result');
        return () => {
            document.body.classList.remove('pc-result');
        };
    });

    return (
        <div className="container-width pc-result-container">
            {allProductsData?.length && <h1>{t(`Your Skin Reveal`)}</h1>}
            {isLoading && <LoadingIndicator />}
            <div id="YMK-module"></div>

            {allProductsData?.length && (
                <>
                    <div className="pc-skin-score-container">
                        <div className="pc-skin-score">
                            <h4>{t('Skin Score')}</h4>
                            <div
                                className="pc-skin-score-circle"
                                onClick={setDefaultFilter}
                            >
                                <span className="pc-score-cross"></span>
                                <span className="pc-score-value">
                                    {ymkData?.skinHealth}
                                </span>
                                <span className="pc-score-outof">/100</span>
                            </div>
                        </div>
                        <div className="pc-skin-score-info">
                            <p>
                                {t(
                                    `Using cutting-edge technology, we have scanned your skin and assigned it an overall 
                                health score - the higher your score, the healthier your skin is. From 1 to 100 we have 
                                assigned a score to each skin concern.`
                                )}
                            </p>
                        </div>
                    </div>
                    {filter !== defaultFilter && (
                        <div className="pc-score-block">
                            <h2 className="pc-score-heading">
                                To get back to your must-have products, tap Skin
                                Score.
                            </h2>
                        </div>
                    )}
                </>
            )}

            {productsData?.length > 0 && (
                <div className="pc-recommendation-wrap">
                    <div className="pc-recommendation-heading">
                        <div className="category-title-wrap">
                            {filter === defaultFilter ? (
                                <>
                                    <h2 className="category-title">
                                        {t(`PRODUCT RECOMMENDATIONS`)}
                                    </h2>
                                    <h3>
                                        {t(
                                            `Based on your Skin Score these are your must-haves`
                                        )}
                                    </h3>
                                </>
                            ) : (
                                <>
                                    <h2 className="category-title">
                                        {t(`TARGETED RECOMMENDATIONS`)}
                                    </h2>
                                    <h3 className="concern-heading">
                                        To Address
                                    </h3>
                                    <h3>{concerns[filter]}</h3>
                                </>
                            )}
                        </div>
                    </div>
                    <ul className="pc-product-list">
                        {productsData?.map(product => (
                            <Product
                                product={product}
                                key={`all-product-${product?.id}`}
                                categoryTitle={
                                    filterCategory(product?.sku, categoryData)
                                        ?.Category
                                }
                                categoryDescription={
                                    filterCategory(
                                        product?.sku,
                                        categoryData
                                    )?.['Category Descriptions']
                                }
                            />
                        ))}
                    </ul>
                </div>
            )}

            {filter !== defaultFilter && routineData?.length > 0 && (
                <div className="pc-recommendation-wrap routine-section">
                    <div className="pc-recommendation-heading">
                        <div className="category-title-wrap">
                            <h3>{t(`Complete your Routine`)}</h3>
                        </div>
                    </div>
                    <ul className="pc-product-list">
                        {routineData?.map(product => (
                            <Product
                                product={product}
                                key={`routine-product-${product?.id}`}
                                categoryTitle={
                                    filterCategory(
                                        product?.sku,
                                        routineCategoryData
                                    )?.Category
                                }
                                categoryDescription={
                                    filterCategory(
                                        product?.sku,
                                        routineCategoryData
                                    )?.['Category Descriptions']
                                }
                            />
                        ))}
                    </ul>
                </div>
            )}

            {allProductsData && (
                <EmailCollectionForm
                    ymkData={ymkData}
                    allProductsData={allProductsData}
                    concerns={concerns}
                    filter={filter}
                    defaultFilter={defaultFilter}
                    productsData={productsData}
                    routineData={routineData}
                    categoryData={categoryData}
                    routineCategoryData={routineCategoryData}
                    allCategoryData={allCategoryData}
                />
            )}
        </div>
    );
};

export default Mobile;
