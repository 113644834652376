import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Price } from 'ModulesPath/Catalog/Price';
import { useTranslation } from 'react-i18next';
import projectConfig from 'SrcPath/project.config';
import addSimpleProductsToCart from 'ModulesPath/App/Queries/addSimpleProductsToCart.graphql';
import addConfigurableProductToCart from 'ModulesPath/App/Queries/addConfigurableProductToCart.graphql';
import addBundleProductsToCart from 'ModulesPath/App/Queries/addBundleProductsToCart.graphql';
import BrowserPersistence from '@magento/peregrine/lib/util/simplePersistence';
import { getDefaultOptions } from './utils/getDefaultOptions';

const persistence = new BrowserPersistence();
const routeCacheKey = 'urlResolver';
// TTL of one day
const ttl = 86400;

const VariantSelector = React.lazy(() =>
    import('@corratech/variant-selector/src/index')
);

const AddToCart = React.lazy(() =>
    import('@corratech/add-to-cart/src/AddToCart')
);

const Product = props => {
    const [t] = useTranslation();
    const { product, categoryTitle, categoryDescription } = props;
    const { url_key, id } = product;
    const [sku, setSku] = useState(product.sku);
    const [optionProduct, setOptionProduct] = useState(product);
    const [image, setImage] = useState(product.small_image);

    const [optionSelected, seOptionSelected] = useState('');
    const [originalProduct] = useState(product);
    const [stockStatus, setStockStatus] = useState(product.stock_status);
    const urlParam = `?auto=webp&optimize=high&format=jpg&width=300`;
    const categoryName = '';

    const isCustomisableBundleProduct = product.exploding_kit
        ? !Number(product.exploding_kit)
        : 1;

    const categoryUrlSuffix = '.html';

    const isConfig = product => {
        return product.__typename == 'ConfigurableProduct';
    };

    const isGiftCard = product => {
        return product && product.__typename === 'GiftCardProduct';
    };

    const isBundleProduct = product => {
        return product && product.__typename === 'BundleProduct';
    };

    const ImageUrl = mainImage => {
        return mainImage.url ? `${mainImage.url}${urlParam}` : '';
    };

    useEffect(() => {
        if (product) {
            setOptionProduct(product);
            setImage(product.small_image);
        }
    }, [product]);

    let componentOOS = false;
    const bundleOptions = isBundleProduct(product)
        ? (() => {
              let defaultBundleOptions = [];
              let isDefaultSet;

              product?.items?.map(item => {
                  isDefaultSet = false;
                  if (item.required) {
                      item.options.map(
                          ({
                              is_default,
                              quantity,
                              id,
                              product: optionProduct
                          }) => {
                              if (is_default) {
                                  if (
                                      optionProduct.stock_status ==
                                      'OUT_OF_STOCK'
                                  ) {
                                      componentOOS = true;
                                  }
                                  defaultBundleOptions.push({
                                      id: item.option_id,
                                      quantity: quantity,
                                      value: [id.toString()]
                                  });
                                  isDefaultSet = true;
                              }
                          }
                      );

                      if (!isDefaultSet && item.options[0]) {
                          if (
                              item.options[0].product.stock_status ==
                              'OUT_OF_STOCK'
                          ) {
                              componentOOS = true;
                          }
                          defaultBundleOptions.push({
                              id: item.option_id,
                              quantity: item.options[0].quantity,
                              value: [item.options[0].id.toString()]
                          });
                      }
                  } else {
                      item.options.map(
                          ({
                              is_default,
                              quantity,
                              id,
                              product: optionProduct
                          }) => {
                              if (is_default) {
                                  if (
                                      optionProduct.stock_status ==
                                      'OUT_OF_STOCK'
                                  ) {
                                      componentOOS = true;
                                  }
                                  defaultBundleOptions.push({
                                      id: item.option_id,
                                      quantity: quantity,
                                      value: [id.toString()]
                                  });
                              }
                          }
                      );
                  }
              });
              return defaultBundleOptions;
          })()
        : {};

    const defaultOptions = getDefaultOptions(product);

    const handleClick = useCallback(() => {
        history.push(`/${product.url_key}${categoryUrlSuffix}`);
    }, []);

    const handleLinkClick = useCallback(() => {
        window.scrollTo(0, 0);
        const routes = persistence.getItem(routeCacheKey) || {};
        routes[`/${url_key}${categoryUrlSuffix}`] = {
            data: {
                urlResolver: {
                    type: 'PRODUCT',
                    id,
                    relative_url: `${url_key}${categoryUrlSuffix}`,
                    redirectCode: 0,
                    __typename: 'EntityUrl'
                }
            }
        };
        persistence.setItem(routeCacheKey, routes, ttl);
    }, []);

    const simpleProductCallback = useCallback(simpleProduct => {
        if (simpleProduct) {
            setSku(simpleProduct.sku);
            setOptionProduct(simpleProduct);
            setStockStatus(simpleProduct.stock_status);
            setImage(simpleProduct.small_image);
        } else {
            setSku(originalProduct.sku);
            setOptionProduct(originalProduct);
            setStockStatus(originalProduct.stock_status);
            setImage(originalProduct.small_image);
        }
    }, []);

    const optionSelectCallback = useCallback(selectedOptions => {
        if (
            selectedOptions?.items?.[0] &&
            defaultOptions?.[0]?.value_index !==
                selectedOptions.items[0].value_index
        ) {
            seOptionSelected(
                `${selectedOptions.items[0].attribute}=${selectedOptions.items[0].value_index}`
            );
        } else {
            seOptionSelected('');
        }
    }, []);

    return (
        <li className="pc-product-item">
            <div className="pc-product-img">
                <img src={ImageUrl(image)} alt={product?.name} />
            </div>
            <div className="pc-product-info">
                <h4>{categoryTitle}</h4>
                <p>{categoryDescription}</p>
                <p className="pc-product-name">
                    <Link
                        onClick={handleLinkClick}
                        to={`/${product.url_key}${categoryUrlSuffix}${
                            optionSelected != '' ? '?' + optionSelected : ''
                        }`}
                        title={product.name}
                    >
                        {product.name}
                    </Link>
                </p>
                {!isGiftCard(product) && (
                    <Fragment>
                        <p className="pc-rec-price">
                            <Price product={optionProduct} inline />
                        </p>
                    </Fragment>
                )}
            </div>

            {isConfig(product) && (
                <div className={'pc-product-list-variants'}>
                    <Fragment>
                        <VariantSelector
                            product={product}
                            optionSelectCallback={optionSelectCallback}
                            simpleProductCallback={simpleProductCallback}
                            defaultOptions={defaultOptions}
                            disableUnselect={true}
                            optionLimit={
                                product.configurable_options[0].values.length >
                                4
                                    ? 4
                                    : false
                            }
                            productUrl={`/${product.url_key}${categoryUrlSuffix}`}
                        />
                    </Fragment>
                </div>
            )}

            <div className="pc-add-to-cart">
                <div
                    className={`add-to-cart-btn ${
                        !isConfig(product) ? 'is-simple-product' : ''
                    } `}
                >
                    {stockStatus == 'IN_STOCK' &&
                    (!isBundleProduct(product) || !componentOOS) ? (
                        <Fragment>
                            {isGiftCard(product) ||
                            (isCustomisableBundleProduct &&
                                isBundleProduct(product)) ? (
                                <Button
                                    onClick={handleClick}
                                    variant={'primary'}
                                >
                                    {t('ADD TO BAG')}
                                </Button>
                            ) : (
                                <AddToCart
                                    addToCartText={t('ADD TO BAG')}
                                    addToCartLoadingText={t('ADDING...')}
                                    addedMessage={t('ADDED')}
                                    quantity={1}
                                    sku={
                                        !!isConfig(product) ? sku : product.sku
                                    }
                                    isConfigProduct={!!isConfig(product)}
                                    isBundleProduct={!!isBundleProduct(product)}
                                    parent_sku={product && product.sku}
                                    isBundled={false}
                                    showTick={false}
                                    projectConfig={projectConfig}
                                    categoryName={categoryName}
                                    configProductAddToCartGraphql={
                                        addConfigurableProductToCart
                                    }
                                    simpleProductAddToCartGraphql={
                                        addSimpleProductsToCart
                                    }
                                    addBundleProductsToCart={
                                        addBundleProductsToCart
                                    }
                                    bundleOptions={bundleOptions}
                                />
                            )}
                        </Fragment>
                    ) : (
                        <Button variant="primary" disabled>
                            {t('OUT OF STOCK')}
                        </Button>
                    )}
                </div>
            </div>
        </li>
    );
};

export default Product;
