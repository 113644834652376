import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormApi } from 'informed';

export const SubmitButton = props => {
    const { loading, onSubmit, btnLabel } = props;
    const [t] = useTranslation();
    const formApi = useFormApi();

    const submitCallback = () => {
        onSubmit(formApi);
    };

    return (
        <Button
            size="lg"
            variant="primary"
            onClick={submitCallback}
            disabled={loading}
        >
            {t(btnLabel)}
        </Button>
    );
};

SubmitButton.propTypes = {
    loading: PropTypes.bool,
    onSubmit: PropTypes.func,
    btnLabel: PropTypes.string
};

SubmitButton.defaultProps = {
    loading: false,
    btnLabel: 'Send'
};
